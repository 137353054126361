import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'eas-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() color = 'white';
  @Input() variant = 'regular';
  @Input() size = 'md';
  @Input() brand = 'rogers';
  constructor() {}

  ngOnInit() {
  }
}
