import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UtilsService } from '@rogers/cdk/common/services';
import { Brand, CDKCoreService } from '@rogers/cdk/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private titleService: Title,
    private utilsService: UtilsService,
    private cdkCore: CDKCoreService
  ) {}

  brandProperty(name: string): string {
    return name + this.utilsService.ucFirst(this.cdkCore.brand);
  }

  changeTitle(brand: Brand) {
    this.titleService.setTitle(`${brand.toUpperCase()}`);
  }
}
