import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { catchError, flatMap, map, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ConfigurationService } from '../shared/configuration.service';
import { WINDOW } from '../shared/window.token';
import { LOCAL_STORAGE } from '../shared/local-storage.token';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';

export interface LiveChatServiceConfig {
  accountId: string;
  websiteId: string;
  floatChatId?: string;
}

@Injectable()
export class LiveChatService {
  private customerErrorMessage = '';
  private selectedAcctNumber = '';
  private visitorEmail = '';
  private visitorName = '';
  private visitorPhone = '';
  private custTrackingid = '';
  private readonly userLogin = '';
  constructor(
    @Inject(LOCAL_STORAGE) private readonly localStorage: Storage,
    @Inject(DOCUMENT) private readonly document: HTMLDocument,
    @Inject(WINDOW) private window: Window,
    private readonly cookieService: CookieService,
    private readonly httpClient: HttpClient,
    private readonly configurationService: ConfigurationService,
    private translateService: TranslateService
  ) {
    // Bold chat scripts should be loaded as soon as possible to
    // make sure any active chat window from other website pops up immediately
    this.initializeBc();
  }

  private get bcvma() {
    return this.window['_bcvma'];
  }

  private get pageViewer() {
    return this.window['pageViewer'];
  }
  private get language() {
    return this.translateService.currentLang;
  }

  private get config(): LiveChatServiceConfig {
    return this.configurationService.config.liveChatServiceConfig;
  }

  staticLiveChatButton(
    translateCBID,
    boldChatButtonId,
    chatButtonNew,
    chatButtonOld,
    errorDesc,
    altText
  ) {
    this.removeChatButtons(chatButtonNew);
    this.removeChatButtons(chatButtonOld);

    // const bccbId = Math.random();
    const bccbId = 0;
    const bdDivVar = this.document.getElementById(boldChatButtonId);
    const childVar = this.document.createElement('div');

    if (errorDesc !== undefined) {
      this.customerErrorMessage = errorDesc;
    }

    childVar.setAttribute('id', String(bccbId));
    childVar.setAttribute('class', chatButtonNew);
    if (bdDivVar) {
      bdDivVar.appendChild(childVar);
    }
    this.setupPageViewer();
    if (this.pageViewer) {
      this.pageViewer.addStatic({ type: 'chat', bdid: translateCBID, id: bccbId });
      this.pageViewer.pageViewed();
    } else {
      this.bcvma.push([
        'addStatic',
        { type: 'chat', bdid: translateCBID, id: bccbId }
      ]);
    }
    if (altText !== null && altText !== undefined) {
      this.addAltText(altText, chatButtonNew);
    }
  }

  triggerChat(buttonId) {
    const element = this.document.getElementById(buttonId);
    if (!element) {
      return;
    }

    const link = element.getElementsByTagName('a') as any;
    if (!link || link.length === 0 || !link[0].click) {
      return;
    }

    link[0].click();
  }

  private readonly bcLoad = () => {
    if (this.window['bcLoaded']) {
      return;
    }
    this.window['bcLoaded'] = true;
    const vms = this.document.createElement('script');
    vms.type = 'text/javascript';
    vms.async = true;
    vms.src = `//vmss.boldchat.com/aid/${this.config.accountId}/bc.vms4/vms.js`;
    const s = this.document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(vms, s);
  };

  private removeChatButtons(chatButton) {
    const elements = this.document.getElementsByClassName(chatButton);
    if (elements && elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        elements[0].parentElement.removeChild(elements[0]);
      }
    }
  }

  private setupPageViewer() {
    if (this.pageViewer && this.pageViewer.load) {
      this.pageViewer.check(1, 2);
      this.window['pageViewer'] = this.window['_bcvm'].getPageViewer();

      this.setPageviewerParameters(true, true);
      return;
    }
    this.setBcvmaParameters(true, true);
  }

  private initializeBc() {
    this.custTrackingid = `au:${this.cookieService.get('aam_uuid')}`;
    this.window['_bcvma'] = window['_bcvma'] || [];
    this.setBcvmaParameters(true, false);
    this.bcvma.push(['pageViewed']);

    if (this.pageViewer && this.pageViewer.load) {
      this.pageViewer.load();
    } else if (this.document.readyState === 'complete') {
      this.bcLoad();
    } else if (window.addEventListener) {
      this.window.addEventListener('load', this.bcLoad, false);
    } else {
      this.window['attachEvent']('onload', this.bcLoad);
    }
  }

  private setCustomerDetails() {
    if (this.isLoggedIn()) {
      this.selectedAcctNumber = this.cookieService.get('accountNumber');
      this.visitorName = this.cookieService.get('fullName');
      this.visitorEmail = this.getEmailId();
      if (this.visitorEmail === undefined) {
        this.visitorEmail = '';
      }
      const ctnNumber = this.cookieService.get('firstCTN');
      if (!!ctnNumber) {
        this.visitorPhone = ctnNumber;
      }
    }
  }

  private secureParams(callback: Function, type: string) {
    this.setCustomerDetails();
    if (this.pageViewer && this.pageViewer.load) {
      this.setPageviewerParameters(false, false);
    } else {
      this.setBcvmaParameters(false, false);
    }
    const allVariablesToPassThrough =
      // tslint:disable-next-line: prefer-template
      `Unsecured=${encodeURIComponent(
        'url&referrer&vn&vr&vi&ve&vp&curl&vicon&oicon&ln&vn&eq&cr&ci&ca&lc&cbdid&cwdid&rdid&roid&ccid&idid&'
      )}`;
    if (type === 'visit' || !this.isLoggedIn()) {
      callback(
        `${allVariablesToPassThrough}customField_CxPageTitle=${encodeURIComponent(
          this.document.title
        )}&customField_custtrackingid=${encodeURIComponent(
          this.custTrackingid
        )}`
      );
    } else {
      this.generateToken((ssoToken, delStatus) => {
        callback(
          `${allVariablesToPassThrough}&customField_ssoToken=${encodeURIComponent(
            ssoToken
          )}&customField_CxLogin=${this.userLogin}&customField_CxAccnt=${
            this.selectedAcctNumber
          }&customField_CxErrorMessage=${encodeURIComponent(
            this.customerErrorMessage
          )}&customField_CxPageTitle=${encodeURIComponent(
            document.title
          )}&customField_custtrackingid=${encodeURIComponent(
            this.custTrackingid
          )}&vi=${delStatus}`
        );
      });
    }
  }

  private generateToken(
    callback: (ssoToken: string, delinquentStatus?: string) => void
  ) {
    forkJoin([
      this.getSSOChatDetails(),
      this.getDelinquentStatus(this.selectedAcctNumber)
    ])
      .pipe(
        flatMap(([ssoChatDetails, delinquentStatus]) => {
          return this.getSSO_Token(ssoChatDetails).pipe(
            tap(token => {
              callback(token, delinquentStatus);
            })
          );
        })
      )
      .subscribe();
  }

  private getDelinquentStatus(selectedAcctNumber: string) {
    const url = '/web/RogersServices.portal/totes/api/v1/delinquentStatus';
    return this.httpClient
      .post<{ status: string }>(url, {
        applicationId: 'Rogers.com',
        accountNumber: selectedAcctNumber
      })
      .pipe(
        map(data => data.status),
        catchError(_ => of('NA'))
      );
  }

  private getSSOChatDetails(): Observable<SSOChatDetails> {
    const url = '/web/RogersServices.portal/totes/api/v1/getSSOChatDetails';
    return this.httpClient.get<SSOChatDetails>(url).pipe(
      map(data => {
        return { ...data, success: true };
      }),
      catchError(_ => of({ success: false }))
    );
  }

  private getSSO_Token(ssoChatDetails: SSOChatDetails): Observable<string> {
    if (ssoChatDetails.success) {
      const { sep, sch, sck } = ssoChatDetails;
      const url = `https://safesend.rogers.com/submit-mr.php?jsoncallback=?&sep=${sep}&sch=${sch}&sck=${sck}`;
      return this.httpClient
        .jsonp<{ stk: string }>(url, 'jsoncallback')
        .pipe(map(data => data.stk));
    }
    return of('sso token error');
  }

  private isLoggedIn() {
    let sessionCookie = this.cookieService.get('Authorization');
    if (!!sessionCookie) {
      return true;
    }

    sessionCookie = this.cookieService.get('Authorization');
    return !!sessionCookie && sessionCookie !== 'LOGGEDOFF';
  }

  private getEmailId() {
    const encryptedEmailId = this.cookieService.get('cbid');
    if (encryptedEmailId) {
      const decryptedEmailId = CryptoJS.AES.decrypt(
        encryptedEmailId,
        'BoldChatSecret'
      );
      return decryptedEmailId.toString(CryptoJS.enc.Utf8);
    }
    return '';
  }

  private setPageviewerParameters(initialize = false, setupPageViewer = false) {
    if (initialize) {
      // accountId = 369303105785976989
      this.pageViewer.setAccountID(this.config.accountId);
      // WebsiteID = 236757985006933441
      this.pageViewer.setParameter('WebsiteID', this.config.websiteId);
    }
    if (setupPageViewer) {
      this.pageViewer.setParameter(
        'SecureParameters',
        this.secureParams.bind(this)
      );
    } else {
      this.pageViewer.setParameter('VisitRef', this.language);
    }
    this.pageViewer.setParameter('VisitEmail', this.visitorEmail);
    this.pageViewer.setParameter('VisitName', this.visitorName);
    this.pageViewer.setParameter('VisitPhone', this.visitorPhone);
  }

  private setBcvmaParameters(initialize = false, setupPageViewer = false) {
    if (initialize) {
      this.bcvma.push(['setAccountID', this.config.accountId]);
      this.bcvma.push(['setParameter', 'WebsiteID', this.config.websiteId]);
      this.bcvma.push(['setParameter', 'CustomUrl', '']);
    }
    if (setupPageViewer) {
      this.bcvma.push([
        'setParameter',
        'SecureParameters',
        this.secureParams.bind(this)
      ]);
    }
    this.bcvma.push(['setParameter', 'VisitEmail', this.visitorEmail]);
    this.bcvma.push(['setParameter', 'VisitName', this.visitorName]);
    this.bcvma.push(['setParameter', 'VisitPhone', this.visitorPhone]);
    this.bcvma.push(['setParameter', 'VisitRef', this.language]);
  }

  private addAltText(altText: string, className: string) {
    const elementName = this.document.getElementsByClassName(className);
    setTimeout(_ => {
      if (!elementName) {
        return;
      }
      for (let i = 0; i < elementName.length; i++) {
        const imageTag = elementName[i].getElementsByTagName('img');
        imageTag[0].setAttribute('alt', altText);
      }
    }, 9000);
  }
}

interface SSOChatDetails {
  success: boolean;
  // url?: string;
  sep?: string;
  sch?: string;
  sck?: string;
}
