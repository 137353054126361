import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-multi-factor-auth-success',
  templateUrl: './multi-factor-auth-success.component.html',
  styleUrls: ['./multi-factor-auth-success.component.scss']
})
export class MultiFactorAuthSuccessComponent implements OnInit {

  emailAddress = null;
  recoveryNumber = null;

  onHomeLinkSubmit: () => void;
  homeLink: string;
  client: string;
  homeLinkAlt: string;

  successLogo: string;

  constructor(private readonly translate: TranslateService, private readonly cookieService:CookieService) { }

  ngOnInit(): void {
    this.setTranslatePropertiesByBrand();
  }

  onHomeLinkClick(){
    this.onHomeLinkSubmit();
  }

  private setTranslatePropertiesByBrand(){
    this.client = this.cookieService.get('client');

    if (this.client === 'r4b'){
      this.homeLink = 'r4b.homeLink';
      this.homeLinkAlt = 'r4b.homeLinkAlt';
    }

    else if (this.client === 'fido'){
      this.successLogo = 'fds-icon-success';
      this.homeLink = 'fido.auth.link';
    }

    else if (this.client === 'media'){
      this.homeLink = 'media.homeLink';
      this.successLogo = 'rds-icon-check-circle';
      this.homeLinkAlt = 'media.homeLinkAlt';
    }
    else if (this.client === 'chatr'){
      this.homeLink = 'chatr.homeLink';
      this.successLogo = 'cds-icon-check-circle';
      this.homeLinkAlt = 'chatr.homeLinkAlt';

    }

    else{
      this.homeLink = 'rogers.auth.link';
      this.homeLinkAlt = 'rogers.auth.accessibility.link';
      this.successLogo = 'rds-icon-check-circle';
    }
  }

}
