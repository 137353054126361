import { ComponentFactory, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from "@angular/core";
import { MultiFactorAuthSuccessComponent } from "./multi-factor-auth-success.component";


class MultiFactorAuthSuccessSession implements com.ts.mobile.sdk.UIFormSession{
    clientContext: object;
    actionContext: object;
    formId: string;
    payload: object;

    submitHandler: any;
    submitBlock: (payload: object) => void;
    resolver: ComponentFactoryResolver;
    viewContainerRef: ViewContainerRef;
    multFactorAuthSuccessComponentRef: ComponentRef<MultiFactorAuthSuccessComponent>;

    constructor(formId: string, payload: object) {
        console.log('Inside Multi-factor Auth SuccessTs form constructor');
        this.formId = formId;
        this.payload = payload;
    }

    startSession(clientContext: object | null, actionContext: com.ts.mobile.sdk.PolicyAction | null): void {
        console.log('Multi-factor Auth SuccessTs session started: ' + this.formId);
        this.clientContext = clientContext;
        this.actionContext = actionContext;
        this.resolver = (clientContext as any).resolver;
        this.viewContainerRef = (clientContext as any).viewContainerRef;
      }

      endSession(): void {
        console.log('Multi-factor Auth SuccessTs session ended: ' + this.formId);
      }

      promiseFormInput(): Promise<com.ts.mobile.sdk.FormInput> {
        console.log('Multi-factor Auth SuccessTs session Input: ' + this.formId);
        const self = this;
        self.viewContainerRef.clear();
        const factory: ComponentFactory<MultiFactorAuthSuccessComponent> =
        self.resolver.resolveComponentFactory(MultiFactorAuthSuccessComponent);

        self.multFactorAuthSuccessComponentRef = self.viewContainerRef.createComponent(factory);
        this.multFactorAuthSuccessComponentRef.instance.emailAddress = this.clientContext['email_mfa'];
        this.multFactorAuthSuccessComponentRef.instance.recoveryNumber = this.clientContext['mobile_mfa'];

        self.multFactorAuthSuccessComponentRef.instance.onHomeLinkSubmit = self.onHomeLinkClick.bind(self);

        console.log('Multi-factor Auth SuccessTs constructor payload:', this.payload);

        return new Promise((resolve, reject) => {
          self.submitBlock = (payload: object) => {
            try {
              resolve(com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest({}));
              console.log('Multi-factor Auth SuccessTs createFormInputSubmissionRequest SUCCESS payload: ', payload);
              self.submitBlock = null;
            } catch (e) {
              console.log('Multi-factor Auth SuccessTs createFormInputSubmissionRequest ERROR payload: ', payload);
              console.error(e);
            }
          };
        });
      }

      onContinue(payload: object): void {
        console.log('Multi-factor Auth SuccessTs onContinue for ' + this.formId + ' payload:', payload);
      }

      onError(payload: object): void {
        console.log('Multi-factor Auth SuccessTs onError for ' + this.formId + ' payload:', payload);
      }

      private onHomeLinkClick() {
        this.submitBlock(null);
      }
}

interface JourneyPayload {
    actions: ActionsInPayload[];
    stat: string;
    code: string;
    flow: string;
  }

interface ActionsInPayload {
    [key: string]: any;
  }

export default MultiFactorAuthSuccessSession;
