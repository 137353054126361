<form  autocomplete="off">
   <div style="display: inline-flex;">
      <div [ngClass]="client === 'fido' ? 'fido-icon' : 'rogers-icon'">
         <ds-icon
         name="{{successLogo}}"
         class="mr-8"
         [color]="'success'"
         size="32"
         title="{{ 'rogers.auth.accessibility.successAlt' | translate }}"
        ></ds-icon>
      </div>
      <h1 class="text-title-4 mb-24 mt-8">
         <span>{{ 'rogers.auth.success.header' | translate }}!</span>
       </h1>

   </div>


    <div class="text-body-lg mb-10 mt-10">
     <p><strong></strong>
        {{ 'rogers.auth.success.body' | translate }}</p>
   </div>

   <div class="text-body-lg mb-10 mt-10" *ngIf="emailAddress">
      <p>{{ 'rogers.auth.email' | translate }}: 
      <strong> {{emailAddress}}</strong>
   </p>
   </div>
   <div class="text-body-lg mb-10 mt-10" *ngIf="recoveryNumber">
      <p>{{ 'rogers.auth.number' | translate }}: 
      <strong> {{recoveryNumber}}</strong></p>
   </div>

  <div *ngIf="emailAddress" class="text-body-lg mb-10 mt-10">
     <p><strong></strong>
        {{ 'rogers.auth.success.footer' | translate }}</p>
   </div>

   <div class="text-body-lg mb-10 mt-10">
      <p><strong></strong>
         {{ 'rogers.auth.success.footerDesc' | translate }}</p>
    </div>

    <button
    type="submit"
    ds-button
    class="w-100 mt-12"
    (click)="onHomeLinkClick()"
    title="{{ homeLinkAlt | translate }}"
    #continueBtn
    >
    <span>{{ homeLink | translate }}</span>
    </button>
 </form>
