import { commonEnvironment } from './common-environment';

export const environment = {
  ...commonEnvironment,
  production: false,
  //Specify timeout in seconds
  sessionTimeout: 1800,
  dynatraceEnv: 'qa',
  recaptchaSiteKey: '6LfMqMwcAAAAAAcYS7a1mwFoA4icQyVejEtAtWNE',
  recaptchaV3SiteKey: ' 6LdCqM4cAAAAAJishD2nwsswUl7_6T-UzVZakCBH',
  analyticsAPIUrl: 'https://rogers-dev.transmit.security/api/v2/auth/assert',
  analyticsUrl: 'https://assets.adobedtm.com/8cf1b6f99d16/6668466f6850/launch-EN7b9c4a0353944c2e8fa7c97e9a08224b-staging.min.js',
  analyticsUrlR4B: 'https://assets.adobedtm.com/8cf1b6f99d16/5119ce42e2ec/launch-9206614d64df-staging.min.js',
  analyticsUrlFido: 'https://assets.adobedtm.com/8cf1b6f99d16/c73a1c919337/launch-ENe5414305fc53414387b48ce7d88fc281-staging.min.js',  
  analyticsUrlChatr: 'https://assets.adobedtm.com/8cf1b6f99d16/5ba693524426/launch-d41654fa91ba-staging.min.js',  
  homeURL: 'https://qa7.rogers.com/',
  fidohomeURL: 'https://qa7.fido.ca/',
  yahoohomeURL: 'https://www.rogers.com/',
  r4bhomeURL: 'https://qa13.rogers.com/mbh/',
  chatrhomeURL: 'https://chatr-cpp-prod1.prod01.eks.rogers.com/',
  liveChatServiceConfig: {
    accountId: '369303105785976989',
    websiteId: '1975063211994161751'
  },
};
