// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

export const commonEnvironment = {
  brand: 'rogers',
  // appVersion: process.env.APP_VERSION,
  buildTimestamp: new Date().getTime(),
  production: false,
  dataDogId: '45d89dfc-51af-4630-a44f-6fb1b83393db',
  dataDogToken: 'pub45db71a89ec53a0e97277e9d6a182305',
  dataDogService: 'rogers-dam',

  rememberMeCookieName: 'rememberMe',
  rememberMeFlag: 'rememberMeFlag',
  rememberMeCookieExpiry: 365,
  passwordValidationPattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#\$]{8,64}$/,
  passwordValidationPatternWithoutLength: /^(?=.*[A-Za-z])(?=.*\d).{2,}$/,
  emailValidationPattern: /^(([^<>(){}&\[\]\\.,;:\s@'"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  loginCookieName: 'login_fail',
  remindMeCookieName: 'remind_me_add_sms',
  remindChangeUsernameCookieName: 'remind_me_change_username',
  cookieExpire: 30,
  remindMeCookieExpiry: 7,
  remindMeSkipCookieExpiry: 30,
  validateEmailLinkTimeout: 1000,
  consumerContactUs: '/consumer/contact-us',
  patUserTempKeyName: 'temp_token_r',
  defaultTargetAfterSignIn: '/consumer/self-serve/overview',
  registrationPageUrl: '/web/totes/#/easylogin/main',
  registrationPrepaidUrl: '/web/totes/#/sso/registration',
  recoverySmsAddUrl: '/web/totes/easylogin/sms/add',
  businessAccountUrl: 'https://www.rogers.com/web/smb/bss/#/signin',
  registerBusinessAccountUrl: 'https://mybusinesshub.rogers.com/self-register',
  liveChatServiceConfig: {
    accountId: '369303105785976989',
    websiteId: '1975063211994161751'
  },
  publicUrlPrefix: '/web/totes/api',
  userStorageKey: 'ute.rogers.signin.user',
  xSessionTokenName: 'xSessionToken',
  x_session_token: '',
  guidName: 'guid',
  profileActivationDateName: 'profileActivationDate'
};
