<ng-container *ngIf = "authModel.authFlow === authFlow.auth">
<form  autocomplete="off">
   <ng-container ssTrackEvent [event]="track.setMFAPageview"></ng-container>
       <h2 class="{{header}}">
          {{ 'rogers.auth.header' | translate }} 
          <ds-popover
            dsSuffix
            triggerLabel="{{ 'rogers.auth.popOverOpen' | translate }}"
            closeLabel="{{ 'rogers.auth.popOverClose' | translate }}"
            content="{{ 'rogers.auth.tip' | translate }} <a href='{{faqLink}}' target='_blank'>{{ 'rogers.auth.learnMore' | translate }}</a>"
            title="“close”"
            closeCaption="{{ 'rogers.auth.popOverClose' | translate }}"
            triggerCaption="{{ 'rogers.auth.popOverOpen' | translate }}"
          >
          </ds-popover>
       </h2>
  
       <div class="text-body-lg mb-10 mt-10">
        <p><strong></strong>
           {{ 'rogers.auth.body' | translate }}</p>
      </div>

      <div class="text-body-lg mb-10 mt-10">
        <p *ngIf="showEmailOption">{{ 'rogers.auth.email' | translate }}: 
         <strong> {{ (emailAddress !== null && emailAddress !==''  && emailAddress !== undefined) 
            ? emailAddress :notFound | translate}}</strong>
      </p>
        <p>{{ 'rogers.auth.number' | translate }}: 
         <strong> {{ (recoveryNumber !== null && recoveryNumber !== '' && recoveryNumber !== undefined) 
            ? recoveryNumber :notFound | translate}}</strong></p>
     </div>
  
     <div class="text-body-lg mb-10 mt-10" *ngIf="skip !== 'noskip'">
        <p><strong></strong>
           {{ 'rogers.auth.footer' | translate }}</p>
      </div>

      <div class="text-body-lg mb-10 mt-10" *ngIf="skip === 'noskip'">
         <p><strong></strong>
            {{ 'rogers.auth.footerNoSkip' | translate }}</p>
       </div>
       <ng-container *ngIf="onMFAEnableClick" ssTrackEvent [event]="track.mfaEnable"></ng-container>

       <button
       type="submit"
       ds-button
       class="w-100 mt-12"
       (click)="onEnableClick()"
       #enableBtn
       >
       <span>{{ 'rogers.auth.enable' | translate }}</span>
       </button>
       <ng-container *ngIf="onMFAEnableSkip" ssTrackEvent [event]="track.mfaSkip"></ng-container>

       <div  *ngIf="skip !== 'noskip'">
         <button
         type="submit"
         ds-button
         variant="secondary"
         class="w-100 mt-12"
         [ngClass]="'mb-48'"
         (click)="onSkipClick()"
         #skipBtn
         >
         <span>{{ 'rogers.auth.skip' | translate }}</span>
         </button>
       </div>

       <!-- <div  *ngIf="skip === 'skip'">
       <div _ngcontent-hjv-c91="" class="ds-border-bottom mb-lg-24 mb-sm-0 mt-2 ds-brcolor-concrete ng-star-inserted"></div>
       <div class="text-body-lg mt-10">
         <p>{{ 'rogers.auth.contact.description' | translate }}
            <a
            ds-link
            variant="icon"
            (click)="onContactUsLink()"
            class="mb-2 ds-color-link"
            [attr.title]="'rogers.auth.accessibility.contactUs' | translate">
            {{ 'rogers.auth.contact.contactus' | translate }}
          </a>      
         </p>
      </div>
   </div> -->

</form>
</ng-container>

<ng-container *ngIf = "authModel.authFlow === authFlow.contactUs">
   <ng-container *ngIf = "authModel.authFlow === authFlow.contactUs" ssTrackEvent [event]="track.setMFAContactUsPageview"></ng-container>
   <h1 class="text-title-4 mb-8">
      {{ 'rogers.auth.contact.header' | translate }}
   </h1>

   <div class="text-body-lg mb-10 mt-10">
    <p><strong></strong>
       {{ 'rogers.auth.contact.body' | translate }}</p>
  </div>

 <div class="text-body-lg mb-10 mt-10">
    <p><strong></strong>
       {{ 'rogers.auth.contact.footer' | translate }}</p>
  </div>

   <button
   type="submit"
   ds-button
   class="w-100 mt-12"
   title="{{ 'rogers.auth.accessibility.contactUs' | translate }}"
   #contactUsBtn
   >
   <span>{{ 'rogers.auth.contact.contactus' | translate }}</span>
   </button>

</ng-container>

<ng-container *ngIf = "authModel.authFlow === authFlow.failure">

   <div class="text-body-lg mb-10 mt-10">
    <p><strong></strong>
       {{ 'rogers.auth.failure.body' | translate }}</p>
  </div>

 <div class="text-body-lg mb-10 mt-10">
    <p><strong></strong>
       {{ 'rogers.auth.failure.footer' | translate }}</p>
  </div>

   <button
   type="submit"
   ds-button
   class="w-100 mt-12"
   title="{{ 'rogers.auth.accessibility.link' | translate }}"
   #homeBtn
   >
   <span>{{ 'rogers.auth.link' | translate }}</span>
   </button>

</ng-container>
  