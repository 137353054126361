import { AnalyticsConfiguration } from './analytics-configuration';

export const modalPopup = 'modal popup';

export const selfServeCompleteEvent = {
  selfServeComplete: true
};

export const pageViewEvent = {
  pageView: true
};

export const selfServeStartEvent = {
  selfServeStart: true
};

export const selfServeStartWithPageViewEvents = {
  pageView: true,
  selfServeStart: true
};

export const selfServeCompleteWithPageViewEvents = {
  pageView: true,
  selfServeComplete: true
};

export const interactionEvent = {
  interaction: true
};

export const interactionWithPageViewEvents = {
  interaction: true,
  pageView: true
};

export const selfServeStartAndInteractionWithPageViewEvents = {
  interaction: true,
  selfServeStart: true,
  pageView: true
};

export const getAnalyticsConfiguration = ({
                                            pageName,
                                            pageHierarchy,
                                            selfServeName,
                                            selfServeType,
                                            events,
                                            interaction,
                                            miscellaneous
                                          }: {
  pageName?: string;
  pageHierarchy?: string[];
  selfServeName?: string;
  selfServeType?: string;
  events?: { [_key: string]: boolean };
  interaction?: { name: string; type: string };
  miscellaneous?: { type: string; name: string };
}) => {
  const page =
    !!pageName && !!pageHierarchy
      ? {
        name: pageName,
        hierarchy: [...pageHierarchy]
      }
      : undefined;
  const selfServe = !!selfServeName
    ? {
      name: selfServeName,
      type: selfServeType
    }
    : {};

  const analyticsConfig = {
    page,
    events,
    selfServe,
    interaction
  };

  return !!miscellaneous
    ? { ...analyticsConfig, miscellaneous }
    : analyticsConfig;
};

// This file can be used to common reusable analytics configuration consts
// Add any application level config here
export const GLOBAL_ANALYTICS_CONFIGURATION: AnalyticsConfiguration = {
  // Global analytics mapping object, each lazy loaded module should provide its own ANALYTICS_CONFIG,
  // module specific config can merge the global config
  mappings: {},
  callBackFunctions: {}
};
