<h1 class="text-title-4 mb-8"  [ngClass]="brand == 'rshm' ? 'rshm-header' : ''">
  {{ header | translate}}
</h1>
<p class="mb-24 text-body-lg"  [ngClass]="brand == 'rshm' ? 'rshm-context' : ''">
  {{ body | translate}}
</p>
<div class="mb-16">
  <form [formGroup]="accountSelectionForm" (ngSubmit)="submitAccountSelection()">
    <ds-form-field>
        <ds-input-label>{{ 'rogers.accountSelection.selectAccount' | translate}}</ds-input-label>
        <select dsNativeControl rssValidateBlur
          formControlName="accountNumber"
          attr.title="Select Account">
          <option *ngFor="let accounts of subscriptionInfoModel"
            [value]="accounts.hhid"
            translate="{{ accounts.addressType.streetName }}"
          >
          <div>
            <span>{{(accounts.addressType.unitNumber ? accounts.addressType.unitNumber + ',' : '') 
              + ' ' +(accounts.addressType.streetNumber ? accounts.addressType.streetNumber : '') 
              + ' ' +accounts.addressType.streetName }} </span> 
            <span>{{ '(' + accounts.accountNumber + ')' }}</span>
        </div>
        </option>
        </select>
      </ds-form-field>

      <div style="margin-top: 30px;">
        <button ds-button
        type="submit"
        role="button"
        class="w-100">
        <span >{{'common.continue' | translate}}</span>
      </button>
      </div>
</form>
</div>


