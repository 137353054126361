import { ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';

class LanguageFormSession implements com.ts.mobile.sdk.UIFormSession{
  clientContext: object;
  actionContext: object;
  formId: string;
  payload: object;

  submitHandler: any;
  submitBlock: (payload: object) => void;
  // currentHandler: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;
  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;

  constructor(formId: string, payload: object) {
      console.log('Inside languageFormTS constructor');
      this.formId = formId;
      this.payload = payload;
  }
  startSession(clientContext: object | null, actionContext: com.ts.mobile.sdk.PolicyAction | null): void {
    console.log('languageFormTS session started: ' + this.formId);
    this.clientContext = clientContext;
    this.actionContext = actionContext;
    this.resolver = (clientContext as any).resolver;
    this.viewContainerRef = (clientContext as any).viewContainerRef;

  }
  endSession(): void {
    console.log('languageFormTS session ended: ' + this.formId);
    console.log('check if user locked');

    setTimeout(this.checkUserLocked, 2000);

  }
  promiseFormInput(): Promise<com.ts.mobile.sdk.FormInput> {
    console.log('languageFormTS session Input: ' + this.formId);
    const self = this;

    return new Promise((resolve, reject) => {
        try {
          const currentLanguage = this.clientContext['cookieService'].get('lang');
          const payload = {language: currentLanguage};

          resolve(com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(Object.assign(payload)));
          console.log('languageFormTS SUCCESS payload: ', payload);
          self.submitBlock = null; // assign null to prevent using the same promise more then once
        } catch (e) {
          console.log('languageFormTS ERROR payload: ', '');
          console.error(e);
        }
    });
  }
  onContinue(payload: object): void {
    console.log('languageFormTS onContinue for ' + this.formId + ' payload:', payload);
  }
  onError(payload: object): void {
    console.log('languageFormTS onError for ' + this.formId + ' payload:', payload);
  }

    // check if we have locked user dialog box in DOM
    private checkUserLocked() {
      const transmitContainerHTML = document.getElementById('transmitContainer').innerHTML;
      const str = 'User Locked';
      const lockedFound = transmitContainerHTML.includes(str);

      if (transmitContainerHTML.indexOf(str) !== -1){
        console.error('User is locked by Transmit');
        const continueBtn: HTMLElement = document.querySelectorAll('button.xmui-button')[0] as HTMLElement;
        console.error('continueBtn ', continueBtn);
        continueBtn.click();
      }
      else {
        console.error('User is NOT locked by Transmit');
      }
    }
}

export default LanguageFormSession;
