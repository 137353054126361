import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import {
  ModalComponent as DsModalComponent,
  ModalRef,
  ModalService,
} from '@rogers/cdk/modal';


@Component({
  selector: 'app-change-username',
  templateUrl: './change-username.component.html',
  styleUrls: ['./change-username.component.scss']
})
export class ChangeUsernameComponent implements OnInit, AfterViewInit {
  @Input() apiErrorTranslationKey: string;
  @Input() email = '';
  changeUsernameInProgress = false;
  @Input() hideBackBtn: boolean;
  emailRegex: RegExp;
  @Input() showLogo = true;
  @Output() back = new EventEmitter<void>();
  @Output() liveChat = new EventEmitter<string>();
  @Output() changeUsername = new EventEmitter<{ email: string }>();
  @ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement>;
  @ViewChild('continueBtn') continueBtn: ElementRef<HTMLElement>;


  changeUsernameForm: FormGroup;
  formSubmitted = false;
  flowFromPayload: string;
  newUsername: string;
  modalRef: ModalRef;
  errorCode = '';
  changeUsernameDesc: string;
  logo: string;
  logoAlt: string;
  error = '';

  onSubmitNewUsername: (payload: object) => void;
  onChangeUsernameSkipClick: () => void;

  track = {
    changeUsernameInputPageview: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'change-username-input',
    },
    changeUsernameInputErrors: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'change-username-errors',
      category: 'self serve',
      type: this.errorCode === 'tooManyAttemptsOrExpired' ? 'user' : 'http',
      code: this.errorCode,
      API: this.configurationService.config.analyticsAPIUrl,
      field: ''
    },
    changeUsernameInputFormError: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'change-username-errors',
      category: 'self serve',
      type: 'form',
      code: '',
      API: '',
      field: 'username'
    },
  };

  constructor(private readonly configurationService: ConfigurationService,
              private viewContainerRef: ViewContainerRef,
              private modalService: ModalService,
              private readonly translate: TranslateService,
              private readonly cookieService: CookieService) {

    this.emailRegex = this.configurationService.config.emailValidationPattern;
  }

  ngOnInit() {


    var element = document.getElementById("transmitContainer");
    element.classList.add("hideTransmitContainer");
    this.setTranslatePropertiesByBrand();

    this.changeUsernameForm = new FormGroup(
      {
        email: new FormControl('', [
          Validators.required,
          Validators.pattern(this.emailRegex)
        ])
      },
      { updateOn: 'submit' }
    );
  }

  ngAfterViewInit() {
    setTimeout(_ => {
      this.emailInput.nativeElement.focus();
    }, 500);
  }

  onSubmit() {
    this.errorCode = '';
    this.changeUsernameInProgress = true;
    this.formSubmitted = true;
    if (this.changeUsernameForm.invalid) {
      this.changeUsernameInProgress = false;
      return;
    }

    this.cookieService.set('newUsername', this.changeUsernameForm.value.email,undefined, undefined, null, true, 'None');
    this.newUsername = this.changeUsernameForm.value.email;
    this.onSubmitNewUsername({
      action: 'submit',
      username: this.changeUsernameForm.value.email,
      flow: 'changeUsername',
    });
  }

  keyDownEnter(event: any) {
    event.preventDefault();
    this.continueBtn.nativeElement.click();
  }

  onLiveChat() {
    this.liveChat.emit();
  }

  onBack() {
    this.back.emit();
  }

  openModal(templateRef: TemplateRef<DsModalComponent>) {
    this.modalRef = this.modalService.open(
      templateRef,
      this.viewContainerRef,
      { backdropClick: false }
    );
  }

  setErrorCode(code){
    this.errorCode = 'rogers.errors.' + code;
  }

  closeModal() {
    this.modalRef.close();
  }

  setOTPErrorCode(errCode) {
    this.error = errCode;
  }

  resetErrorMessage() {
    this.error = '';
  }
  get isLogoLinked() {
    return false;
  }

  get homeLinkUrl() {
    const homeURL = this.configurationService.config.homeURL;
    return homeURL + `?language=${this.translate.currentLang}`;
  }

  get urlTarget() {
    return '_self';
  }


  get usernameFormError() {
    return {
      category: 'self serve',
      type: 'form',
      code: '',
      API: '',
      field: 'username'
    };
  }

  onChangeUsernameSkip(event){
    event.preventDefault();
    this.onChangeUsernameSkipClick();
  }

  private setTranslatePropertiesByBrand(){

    const client = this.cookieService.get('client');
    if (client === 'media'){
      this.changeUsernameDesc = 'media.changeUsernameForm.body';
    }
    else if (client === 'fido') {
      this.changeUsernameDesc = 'fido.changeUsernameForm.body';
      this.logo = './idp/__default/assets/images/f-bg-1.png';
    }
    else if (client === 'r4b') {
      if (this.translate.currentLang === 'fr')
      {
        this.logo = './idp/__default/assets/images/r4blogo_fr.png';
      }
      else
      {
        this.logo = './idp/__default/assets/images/r4blogo_en.png';
      }
    }
    else if (client === 'yahoo') {
      this.logo = './idp/__default/assets/images/yahoo.png';
    }
    else {
      this.changeUsernameDesc = 'rogers.changeUsername.changeUsernameForm.body';
      this.logo = './idp/__default/assets/images/og-image.png';
    }
  }
}

