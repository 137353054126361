import UsernameFormSession from '../usernameForm/usernameFormTs';
import PasswordAuthenticatorSession from '../passwordForm/passwordFormTs';
import OTPSession from '../otpSession+UI/otpSession';
import ChangeUsernameFormSession from '../change-username/changeUsernameTs';
import ChangeRecoveryNumberFormSession from '../change-recovery-number/changeRecoveryNumberTs';
import ChangePasswordFormSession from '../change-password/changePasswordTs';
import ConfirmChangePasswordFormSession from '../change-password/confirmPasswordTs';
import AccountSelectionSession from '../account-selection/accountSelectionFormTs';
import LanguageFormSession from '../set-language/languageFormTs';
import RegisterFormSession from '../register/registerFormTs';
import SetMultiFactorAuthSession from '../set-multi-factor-auth/setMultiFactorAuthTs';
import MultiFactorAuthSuccessSession from '../multi-factor-auth-success/multiFactorAuthSuccessTs';
import SecurityQuestionFormSession from '../security-question/securityQuestionFormTs';
import UpdateMultiFactorAuthSession from '../update-multi-factor-auth/updateMultiFactorauthFormTs';
import UsernameNoRecaptchaFormSession from '../username-form-no-recaptcha/UsernameNoRecaptchaFormSession';


const xmui: com.ts.mobile.sdk.UIHandler = (window as any).xmui;
let clientContextObj: object;

function CustomUIHandler() {
    (xmui as any).XmUIHandler.call(this);
}

CustomUIHandler.prototype = Object.create((xmui as any).XmUIHandler.prototype);
CustomUIHandler.prototype.constructor = CustomUIHandler;

CustomUIHandler.prototype.createOtpAuthSession = (
  title: string,
  username: string,
  possibleTargets: Array<com.ts.mobile.sdk.OtpTarget>,
  autoExecedTarget: com.ts.mobile.sdk.OtpTarget | null
): com.ts.mobile.sdk.UIAuthenticatorSessionOtp => {
  return new OTPSession(title, username, possibleTargets, autoExecedTarget);
};

// Transmit provided code
// Invoked anytime when the journey is ended

CustomUIHandler.prototype.controlFlowEnded =
function controlFlowEnded(error,clientContext)
{
    if (error) {
      console.error('error ', error.getData().failure_data.reason.type);
      console.log('error: ', error);
    } else {
    console.log('control flow ended');
    }
  };

CustomUIHandler.prototype.createFormSession = (
  formId: string,
  payload: any
): com.ts.mobile.sdk.UIFormSession => {
  console.log('createFormSession', formId);
  switch (formId) {
      case 'usernamePrompt':
      console.log('usernameForm session started', formId);
      return new UsernameFormSession(formId, payload);

      case 'changeUsernameProfile':
      console.log('change usernameForm session started', formId);
      return new ChangeUsernameFormSession(formId, payload);

      case 'changeRecoveryNumberProfile':
      console.log('change recoveryNumberForm session started', formId);
      return new ChangeRecoveryNumberFormSession(formId, payload);

      case 'selectAccount':
      console.log('select account session started', formId);
      return new AccountSelectionSession(formId, payload);

      case 'language':
      console.log('set language session started', formId);
      return new LanguageFormSession(formId, payload);

      // registration
      case 'create':
      console.log('register session started', formId);
      clientContextObj['cookieService'].set('register', formId, undefined, undefined, null, true, 'None');
      return new RegisterFormSession(formId, payload);

      // MFA
      case 'registerMFA':
      console.log('MFA session started', formId);
      return new SetMultiFactorAuthSession(formId, payload);

      case 'mfaSuccessForm':
      console.log('MFA success session started', formId);
      return new MultiFactorAuthSuccessSession(formId, payload);

      case 'profileUpdateMFA':
      console.log('Profile update-MFA success session started', formId);
      return new UpdateMultiFactorAuthSession(formId, payload);

      case 'username-no-recaptcha':
        console.log('Username-no-recaptcha session started', formId);
        return new UsernameNoRecaptchaFormSession(formId, payload);

  }
};

CustomUIHandler.prototype.createPasswordAuthSession = (
  title: string,
  username: string,
) => {
  console.log('PasswordAuthSession invoke');
  const flow =  clientContextObj['cookieService'].get('customFlow');
  if (flow === 'oldPassword'){
    return new ChangePasswordFormSession(title, username);
  }
  else if (flow === 'newPassword'){
    return new ConfirmChangePasswordFormSession(title, username);
  }
  else{
    return new PasswordAuthenticatorSession(title, username);
  }
};

CustomUIHandler.prototype.createPlaceholderAuthSession  = (
  placeholderName, placeholderType, title, username,
  authenticatorConfiguredData, serverPayload
) => {
  console.log('Yahoo ForgotPassword session started');
  return new SecurityQuestionFormSession(title, username);
};


CustomUIHandler.prototype.handlePolicyRedirect  = (
  redirectType: com.ts.mobile.sdk.RedirectInput, policyId: string | null,
  userId: string | null, additionalParameters: object | null, clientContext: object | null
) => {

    clientContext['UserId'] = userId;
    return new Promise((resolve, reject) => {
        try {
          resolve(com.ts.mobile.sdk.RedirectInput.create(com.ts.mobile.sdk.RedirectResponseType.RedirectToPolicy));
        }
        catch (e) {
          console.log('handlePolicyRedirect error: ', e);
        }
    });
};


CustomUIHandler.prototype.handlePolicyRejection =
(title, text, buttonText, failureData, actionContext, clientContext) => {
  return Promise.resolve(com.ts.mobile.sdk.ConfirmationInput.create(-1));
};


CustomUIHandler.prototype.processJsonData = (
  jsonData: object, actionContext: com.ts.mobile.sdk.PolicyAction, clientContext: object | null
): Promise<com.ts.mobile.sdk.JsonDataProcessingResult> => {
  clientContextObj = clientContext;
  return new Promise((resolve, reject) => {
    if (jsonData['userID']) {

       clientContext['userID'] = jsonData['userID'];
       clientContextObj['cookieService'].set('userEmail', jsonData['userID'], undefined, undefined, null, true, 'None');
      }
    if (jsonData['username']) { clientContext['username'] = jsonData['username']; }
    if (jsonData['flow']) {
      clientContext['flow'] = jsonData['flow'];
      clientContextObj['cookieService'].set('customFlow', clientContext['flow'], undefined, undefined, null, true, 'None');
      clientContextObj['cookieService'].set('flow', clientContext['flow'], undefined, undefined, null, true, 'None');
      clientContextObj['cookieService'].set('regFlow', clientContext['flow'], undefined, undefined, null, true, 'None');
    }
    if (jsonData['redirect_uri']) {
      clientContext['redirect_uri'] = jsonData['redirect_uri'];
      clientContextObj['cookieService'].set('redirect_uri', jsonData['redirect_uri'], undefined, undefined, null, true, 'None');
    }
    if (jsonData['state']) {
      clientContext['state'] = jsonData['state'];
      clientContextObj['cookieService'].set('state', jsonData['state'], undefined, undefined, null, true, 'None');
    }

    // MFA
    if (jsonData['email_mfa']) {
      clientContext['email_mfa'] = jsonData['email_mfa'];
      clientContextObj['cookieService'].set('email_mfa', jsonData['email_mfa'], undefined, undefined, null, true, 'None');
    }
    if (jsonData['mobile_mfa']) {
      clientContext['mobile_mfa'] = jsonData['mobile_mfa'];
      clientContextObj['cookieService'].set('mobile_mfa', jsonData['mobile_mfa'], undefined, undefined, null, true, 'None');
    }
    if (jsonData['skip_mfa']) {
      clientContext['skip_mfa'] = jsonData['skip_mfa'];
      clientContextObj['cookieService'].set('skip_mfa', jsonData['skip_mfa'], undefined, undefined, null, true, 'None');
    }

    // Security Question
    if (jsonData['securityQ']) {
      clientContext['securityQ'] = jsonData['securityQ'];
      clientContextObj['cookieService'].set('securityQ', jsonData['securityQ'], undefined, undefined, null, true, 'None');
    }
    // Profile update MFA
    if (jsonData['MFAEmailStatus']) {
      clientContext['MFAEmailStatus'] = jsonData['MFAEmailStatus'];
    }
    if (jsonData['MFAMobileStatus']) {
      clientContext['MFAMobileStatus'] = jsonData['MFAMobileStatus'];
    }

    resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(true));
  });
};

export default CustomUIHandler;
