import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-set-multi-factor-auth',
  templateUrl: './set-multi-factor-auth.component.html',
  styleUrls: ['./set-multi-factor-auth.component.scss']
})
export class SetMultiFactorAuthComponent implements OnInit {

  isEmailRegistered = false;
  isNumberRegistered = false;
  disableSkip = false;

  emailAddress = null;
  recoveryNumber = null;
  notFound = 'rogers.auth.notFound';
  skip = null;

  readonly authFlow = AuthFlow;
  authModel = new AuthModel();

  onMFAEnableSubmit: (payload: object) => void;
  onMFASkipSubmit: (payload: object) => void;
  faqLink = '';

  header = '';
  onMFAEnableClick = false;
  onMFAEnableSkip = false;

  showEmailOption = true;


  track = {
    setMFAPageview: {
      pageView: true,
      isModalPopup: false,
      mappingKey: '',
      additionalPayload: {
        miscellaneous: {
          type: 'mfa',
          name: '',
        }
      }      
    },
    mfaEnable: {
      pageView: false,
      isModalPopup: false,
      mappingKey: '',
      category: 'self serve',
      type: 'transaction',
      code: '',
      API: this.configurationService.config.analyticsAPIUrl,
      field: ''
    },
    mfaSkip: {
      pageView: false,
      isModalPopup: false,
      mappingKey: '',
      category: 'self serve',
      type: 'transaction',
      code: '',
      API: this.configurationService.config.analyticsAPIUrl,
      field: ''
    },
    setMFAContactUsPageview: {
      pageView: true,
      isModalPopup: false,
      mappingKey: ''          
    },
  };


  constructor(private readonly translate: TranslateService,
    private readonly configurationService: ConfigurationService,
    private readonly cookieService:CookieService) { }

  ngOnInit(): void {

    if (this.recoveryNumber !== null){
      this.recoveryNumber = this.formatPhoneNumber(this.recoveryNumber);
    }
    this.authModel.authFlow = this.authFlow.auth;

    const client = this.cookieService.get('client');

    if (client === 'rogers'){
      this.faqLink = 'https://www.rogers.com/support/billing-accounts/multi-factor-authentication-faq';
      this.header = 'text-title-8 mb-8';
    }

    else if (client === 'fido'){
      this.faqLink = 'https://www.fido.ca/support/account/multi-factor-authentication-faq';
      this.header = 'text-title-4 mb-8';
    }

    else if (client === 'r4b'){
      this.faqLink = 'https://www.rogers.com/business/support/my-account/multi-factor-authentication';
      this.header = 'text-title-4 mb-8';
    }

    if (client === 'yahoo'){
      this.showEmailOption = false;
    }

    this.setAnalyticsData();
  }

  setAnalyticsData() {
    if (this.emailAddress !== null 
        && this.emailAddress !==''  
        && this.emailAddress !== undefined)
    {
      this.isEmailRegistered = true;
    }

    if (this.recoveryNumber !== null && this.recoveryNumber !=='' && this.recoveryNumber !== undefined)
    {
      this.isNumberRegistered = true;
    }
    if (this.isEmailRegistered && this.isNumberRegistered)
    {
        this.track.setMFAPageview.mappingKey = 'modal-mfa-all';
        // If skip button is not shown to the user, then it is force
        // if skip button is shown then it is nudge
        if (this.skip !== 'noskip' )
        {
          this.track.mfaEnable.mappingKey = 'enable-mfa-enrollment-all-nudge';

        }
        else
        {
          this.track.mfaEnable.mappingKey = 'enable-mfa-enrollment-all-force';
        }
        this.track.mfaSkip.mappingKey = 'skip-mfa-enrollment-all';
        this.track.setMFAPageview.additionalPayload.miscellaneous.name 
        = 'email : yes|wireless recovery number : yes';
    }
    else if (this.isEmailRegistered)
    {
        this.track.setMFAPageview.mappingKey = 'modal-mfa-email';
        if (this.skip !== 'noskip' )
        {
          this.track.mfaEnable.mappingKey = 'enable-mfa-enrollment-email-nudge';

        }
        else
        {
          this.track.mfaEnable.mappingKey = 'enable-mfa-enrollment-email-force';
        }
        this.track.mfaSkip.mappingKey = 'skip-mfa-enrollment-email';
        this.track.setMFAPageview.additionalPayload.miscellaneous.name 
        = 'email : yes|wireless recovery number : no';
    }
    else if (this.isNumberRegistered)
    {
        this.track.setMFAPageview.mappingKey = 'modal-mfa-number';
        if (this.skip !== 'noskip' )
        {
          this.track.mfaEnable.mappingKey = 'enable-mfa-enrollment-number-nudge';

        }
        else
        {
          this.track.mfaEnable.mappingKey = 'enable-mfa-enrollment-number-force';
        }
        this.track.mfaSkip.mappingKey = 'skip-mfa-enrollment-number';
        this.track.setMFAPageview.additionalPayload.miscellaneous.name 
        = 'email : no|wireless recovery number : yes';
    }
    else 
    {
        this.track.setMFAPageview.mappingKey = 'modal-mfa-none';
        if (this.skip !== 'noskip' )
        {
          this.track.mfaEnable.mappingKey = 'enable-mfa-enrollment-none-nudge';

        }
        else
        {
          this.track.mfaEnable.mappingKey = 'enable-mfa-enrollment-none-force';
        }
        this.track.mfaSkip.mappingKey = 'skip-mfa-enrollment-none';
        this.track.setMFAContactUsPageview.mappingKey = 'modal-contactus-mfa';
        this.track.setMFAPageview.additionalPayload.miscellaneous.name
        = 'email : no|wireless recovery number : no';
    }
  }

  onContactUsLink(){
    this.authModel.authFlow = this.authFlow.contactUs;
  }

  onEnableClick(){
    this.onMFAEnableSubmit({
      enable: true,
      skip: false,
      language: this.cookieService.get('lang')
    });
    this.onMFAEnableClick = true;
  }

  onSkipClick(){
    this.onMFASkipSubmit({
      enable: false,
      skip: true,
      language: this.cookieService.get('lang')
    });
    this.onMFAEnableSkip = true;

  }

   formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

}

export enum AuthFlow {
    auth,
    success,
    failure,
    contactUs
}

export class AuthModel {
  authFlow: AuthFlow;
  email: string;
  number: string;
}
