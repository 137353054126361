import { ComponentFactory, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { AccountSelectionComponent } from './account-selection.component';

class AccountSelectionSession implements com.ts.mobile.sdk.UIFormSession{
  clientContext: object;
  actionContext: object;
  formId: string;
  payload: object;

  submitHandler: any;
  submitBlock: (payload: object) => void;
  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;
  accountSelectionFormSessionCompRef: ComponentRef<AccountSelectionComponent>;

  constructor(formId: string, payload: object) {
      console.log('Inside accountSelectionFormTs constructor');
      this.formId = formId;
      this.payload = payload;
  }
  startSession(clientContext: object | null, actionContext: com.ts.mobile.sdk.PolicyAction | null): void {
    console.log('Account Selection Form session started: ' + this.formId);
    this.clientContext = clientContext;
    this.actionContext = actionContext;
    this.resolver = (clientContext as any).resolver;
    this.viewContainerRef = (clientContext as any).viewContainerRef;

  }

  endSession(): void {
    console.log('Account Selection Form session ended: ' + this.formId);
    console.log('User locked');
  }

  promiseFormInput(): Promise<com.ts.mobile.sdk.FormInput> {
    console.log('Form session Input: ' + this.formId);
    const self = this;
    self.viewContainerRef.clear();

    const factory: ComponentFactory<AccountSelectionComponent> = self.resolver.resolveComponentFactory(AccountSelectionComponent);
    self.accountSelectionFormSessionCompRef = self.viewContainerRef.createComponent(factory);


    self.accountSelectionFormSessionCompRef.instance.onAccountSubmit = self.onSubmitAccountSelection.bind(self);;

    self.accountSelectionFormSessionCompRef.instance.subscriptionInfoModel = this.payload['body']['subscriptionInfo'];
    console.log('accountSelectionFormTs constructor payload:', this.payload);

    const journeyPayload = this.payload as JourneyPayload;
    console.log('payload actions using interface', journeyPayload.actions);


    return new Promise((resolve, reject) => {
      self.submitBlock = (payload: object) => {
        try {
          resolve(com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(Object.assign(payload)));
          console.log('accountSelectionFormTs createFormInputSubmissionRequest SUCCESS payload: ', payload);
          self.submitBlock = null; // assign null to prevent using the same promise more then once
        } catch (e) {
          console.log('accountSelectionFormTs createFormInputSubmissionRequest ERROR payload: ', payload);
          console.error(e);
        }
      };
    });
  }

  onContinue(payload: object): void {
    console.log('accountSelectionFormTs onContinue for ' + this.formId + ' payload:', payload);
  }

  onError(payload: object): void {

    console.log('accountSelectionFormTs onError for ' + this.formId + ' payload:', payload);
    const errorPayload = payload as JourneyPayload;
  }

  /** Non UIFormSession interface Functions */
  private onSubmitAccountSelection(payload: object) {
    console.log('accountSelectionFormTs onSubmitAccountSelection for ' + this.formId + ' payload:', payload);
    const data = payload;
    this.submitBlock(data);
  }
}

interface JourneyPayload {
  actions: ActionsInPayload[];
  stat: string;
  code: string;
  flow: string;
}

interface ActionsInPayload {
  [key: string]: any;
}

export default AccountSelectionSession;
