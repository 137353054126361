import { Inject, Injectable } from '@angular/core';
import { DtrumApi, PropertyMap } from '@dynatrace/dtrum-api-types';
import { ConfigurationService } from './configuration.service';
import { WINDOW } from './window.token';
import { SESSION_STORAGE } from './session-storage.token';
declare const dtrum: DtrumApi;



interface UserMonitoringCommonProperties {
  env: string;
  service: string;
  version: string;
}

export interface UserMonitoringConfiguration {
  env: string;
  isEnable: boolean;
  service: string;
}



@Injectable({ providedIn: 'root' })
export class UserMonitoringService {
  private isActive = false;

  constructor(
    private readonly configurationService: ConfigurationService,
    @Inject(SESSION_STORAGE) private sessionStorage: Storage,
    @Inject(WINDOW) private window
  ) {}

  bootstrap() {
    
    if (this.window && typeof dtrum !== 'undefined') {
      this.isActive = true;
      console.log('Dynatrace is initialized');
    } else {
      console.log('Dynatrace was not initialized');
      return;
    }

    this.updateSessionContext(this.getCommonProperties());
  }

  private getCommonProperties(): UserMonitoringCommonProperties {
    return {
      version: this.configurationService.config.appVersion,
      service: 'EAS',
      env: this.configurationService.config.dynatraceEnv,
    };
  }

  // Update session context by using session storage which is captured in Dynatrace automatically
  updateSessionContext(context: UserMonitoringCommonProperties): void {
    if (!this.isActive) return;

    Object.entries(context).forEach(([key, val]) => this.sessionStorage.setItem(`rum${key}`, val));
  }

  // Identify the user. Example: username, customerId, etc
  identifyUser(userName: string) {
    if (!this.isActive) return;
    console.log('identifyUser : ', userName);
    dtrum.identifyUser(userName);
  }

  // Set custom action with properties
  setAction(name: string, context: PropertyMap<string | number> = {}) {
    if (!this.isActive) return;
    const newContext = Object.entries(context).reduce(
      (acc, [key, val]) => ({
        num: {
          ...acc.num,
          ...((typeof val === 'number' && { [key]: val }) || {}),
        },
        str: {
          ...acc.str,
          ...((typeof val === 'string' && { [key]: val }) || {}),
        },
      }),
      { num: {}, str: {} }
    );
    const callback = (id: number) => dtrum.addActionProperties(id, null, null, newContext?.str, newContext?.num);
    dtrum.addEnterActionListener(callback);
    dtrum.enterAction(name);
    dtrum.removeEnterActionListener(callback);
  }
}
