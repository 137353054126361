import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'authenticated-user',
    templateUrl: './authenticated-user-ui.html',
    styleUrls: []
})
export class AuthenticatedUser implements OnInit {

    

    constructor() { }

    ngOnInit() {
        console.log("Inside AuthenticatedUser");
    }
    onLogout = ()=> {
    
        console.log("Inside logout");
    }
    
}
