import { ComponentFactory, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { ChangeRecoveryNumberComponent } from '../change-recovery-number/change-recovery-number.component';
import { ConfirmRecoveryNumberComponent } from '../change-recovery-number/confirm-recovery-number/confirm-recovery-number.component';
import { RecoveryNumberOtpConfirmationComponent } from '../change-recovery-number/recovery-number-otp-confirmation/recovery-number-otp-confirmation.component';
import { ConfirmChangeUsernameComponent } from '../change-username/confirm-change-username/confirm-change-username.component';
import { OtpConfirmationComponent } from '../change-username/otp-confirmation/otp-confirmation.component';
import { OTPDeviceList } from './deviceList/otpDeviceList.component';
import { OTPWaitingForInput } from './otpWaitingForInput.component';

class OTPSession implements com.ts.mobile.sdk.UIAuthenticatorSessionOtp {

    resolver: ComponentFactoryResolver;
    viewContainerRef: ViewContainerRef;
    otpDeviceListRef: ComponentRef<OTPDeviceList>;
    otpWaitingForInputRef: ComponentRef<OTPWaitingForInput>;
    confirmChangeUsernameComponent: ComponentRef<ConfirmChangeUsernameComponent>;
    changeUsernameOtpInputComponent: ComponentRef<OtpConfirmationComponent>;
    confirmRecoveryNumberComponent: ComponentRef<ConfirmRecoveryNumberComponent>;
    recoveryNumberOtpConfirmationComponent: ComponentRef<RecoveryNumberOtpConfirmationComponent>;
    changeRecoveryNumberComponentRef: ComponentRef<ChangeRecoveryNumberComponent>;

    targets: Array<com.ts.mobile.sdk.AuthenticatorTarget> = [];

    clientContext: object;
    mode: com.ts.mobile.sdk.AuthenticatorSessionMode;

    description: any;
    actionContext: any;
    email: string;
    mobile: string;
    // format: com.ts.mobile.sdk.OtpFormat | null;
    // target: com.ts.mobile.sdk.OtpTarget | null;

    currentHandler: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;

    constructor(title: string, username: string,
                possibleTargets: Array<com.ts.mobile.sdk.OtpTarget>,
                autoExecedTarget: com.ts.mobile.sdk.OtpTarget | null) {

    }


    setGeneratedOtp = (format: com.ts.mobile.sdk.OtpFormat | null, target: com.ts.mobile.sdk.OtpTarget | null): void => {
        const flow = this.clientContext['nextStep'];
        console.log('flow =>', flow);
        if (format && target) {
            if (flow === 'changeUsernameFlow'){
                this.renderChangeUsernameOtpInputComponent(format, target);
            }
            else if (flow === 'changeRecoveryNumberFlow'){

                const context = this.actionContext;
                this.renderRecoveryNumberOtpInputComponent(format, target);
            }
            else{
                this.renderWaitingForInput(format, target);
            }
        }
        else {
            if (flow === 'changeUsernameFlow'){
               this.renderConfirmChangeUsernameComponent();
            }
            else if (flow === 'changeRecoveryNumberFlow'){
                this.renderConfirmRecoveryNumberComponent();
            }
            else{
                 this.renderTargetsComponent();
            }
        }
    }

    setAvailableTargets = (targets: Array<com.ts.mobile.sdk.AuthenticatorTarget> | null): void => {
        this.targets = targets || [];
    }

    startSession = (
        description: com.ts.mobile.sdk.AuthenticatorDescription, mode: com.ts.mobile.sdk.AuthenticatorSessionMode, 
        actionContext: com.ts.mobile.sdk.PolicyAction | null, clientContext: object | null
    ): void => {
        this.clientContext = clientContext;
        this.mode = mode;
        this.resolver = (clientContext as any).resolver;
        this.viewContainerRef = (clientContext as any).viewContainerRef;

        this.description = description;
        this.actionContext = actionContext;
        console.log(`Started OTP session with mode ${mode}`);
        this.clientContext['cookieService'].set('OTPERROR', '', undefined, undefined, null, true, 'None');

        console.log('OTPERROR = ', this.clientContext['cookieService'].get('OTPERROR'));

        this.email = actionContext.getUiContext().getString('email');
        this.mobile = actionContext.getUiContext().getString('mobile');

    }

    promiseInput = (): Promise<com.ts.mobile.sdk.InputOrControlResponse<any>> => {
        return new Promise((resolve, reject) => {
            this.currentHandler = (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => {
                resolve(response);
            };
        });
    }

    /*
    Error returned:


    1. Incorrect PIN entered
    {
        "_errorCode": 1,
        "_message": "Authentication resulted in failure",
        "_data": {
            "assertion_error_code": 5,
            "additional_data": {
            "wrong_inputs_left": 1
            },
            "public_properties": {}
        }
    }

    2. Expried PIN entered

    {
        "_errorCode": 1,
        "_message": "Authentication resulted in failure",
        "_data": {
            "assertion_error_code": 5,
            "additional_data": {
            "additional_error_code": 1
            },
            "public_properties": {}
        }
    }

    3. Incorrect PIN entered too many times 

    {
        "_errorCode": 1,
        "_message": "Authentication resulted in failure",
        "_data": {
            "assertion_error_code": 5,
            "additional_data": {
            "additional_error_code": 1
            },
            "public_properties": {}
        }
    }


    */

    promiseRecoveryForError = (
        error: com.ts.mobile.sdk.AuthenticationError, validRecoveries: Array<com.ts.mobile.sdk.AuthenticationErrorRecovery>, 
        defaultRecovery: com.ts.mobile.sdk.AuthenticationErrorRecovery) : Promise<com.ts.mobile.sdk.AuthenticationErrorRecovery> => {
        return new Promise((resolve, reject) => {
            console.log(`promiseRecoveryForError was called with error: ${error}`);
            console.log(JSON.stringify(error,null,2));

            const profilePage = this.clientContext['cookieService'].get('profilePage');

            if (defaultRecovery === com.ts.mobile.sdk.AuthenticationErrorRecovery.RetryAuthenticator) {

               /*  if (window.confirm(error.getMessage() + ', would you like to try again ?')) {
                    resolve(defaultRecovery);
                } else {
                    resolve(com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail);
                } */
                // extra error data

                if (this.keyExists(error, 'wrong_inputs_left') && !(this.keyExists(error, 'additional_error_code'))
                    )
                {
                    // const attemptsLeft = error['_data'].additional_data['wrong_inputs_left']
                    this.clientContext['cookieService'].set('OTPERROR', 'invalid', undefined, undefined, null, true, 'None');

                    setTimeout(() =>
                        {
                            // this.otpWaitingForInputRef.instance.setErrorCode("invalid")
                            this.renderOTPComponent(profilePage, 'invalid');
                        }
                        , 100);
                    resolve(defaultRecovery);
                } 
                else if (this.keyExists(error, 'retries_left')) // self reg OTP invalid error
                {
                    // const attemptsLeft = error['_data'].additional_data['wrong_inputs_left']
                    this.clientContext['cookieService'].set('OTPERROR', 'invalid', undefined, undefined, null, true, 'None');

                    setTimeout(() =>
                        {
                            // this.otpWaitingForInputRef.instance.setErrorCode("invalid")
                            this.renderOTPComponent(profilePage, 'invalid');
                        }
                        , 100);
                    resolve(defaultRecovery);
                }
                else if (this.keyExists(error, 'additional_error_code'))
                {
                    this.clientContext['cookieService'].set('OTPERROR', 'tooManyAttemptsOrExpired', undefined, undefined, null, true, 'None');

                    setTimeout(() =>
                        {
                            this.otpDeviceListRef.instance.setErrorCode('tooManyAttemptsOrExpired');
                        }
                        , 100);
                    resolve(defaultRecovery);
                }
                else
                {
                    this.clientContext['cookieService'].set('OTPERROR', 'other', undefined, undefined, null, true, 'None');

                    console.error(`promiseRecoveryForError was called with other error`);
                    setTimeout(() =>
                        {
                            // this.otpWaitingForInputRef.instance.setErrorCode("other")
                            this.renderOTPComponent(profilePage, 'other');
                        }
                        , 100);
                    resolve(defaultRecovery);

                }

            } else {
                console.error('Unexpected Error');
                setTimeout(() =>
                {
                    this.renderOTPComponent(profilePage, 'other');
                }, 100);
                resolve(defaultRecovery);
            }
        });
    }


    private keyExists(obj, key)  {
        if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
          return false;
        }
        else if (obj.hasOwnProperty(key)) {
          return true;
        }
        else if (Array.isArray(obj)) {
          for (let i = 0; i < obj.length; i++) {
            const result = this.keyExists(obj[i], key);
            if (result) {
              return result;
            }
          }
        }
        else {
          for (const k in obj) {
            const result = this.keyExists(obj[k], key);
            if (result) {
              return result;
            }
          }
        }
        return false;
    }

    changeSessionModeToRegistrationAfterExpiration = (): void => {

    }

  //  check if we have locked user dialog box in dom
  private checkUserLocked() {
    const transmitContainerHTML = document.getElementById('transmitContainer').innerHTML;
    const str = 'User Locked';
    const lockedFound = transmitContainerHTML.includes(str);
    // console.log('transmitContainerHTML ', transmitContainerHTML);


    if (transmitContainerHTML.indexOf(str) !== -1)
    {
      console.error('User is locked by Transmit');
      const continueBtn: HTMLElement = document.querySelectorAll('button.xmui-button')[0] as HTMLElement;
      console.error('continueBtn ', continueBtn);
      continueBtn.click();
    }
    else {
      console.error('User is NOT locked by Transmit');
    }
  }

   endSession = () => {
        setTimeout(this.checkUserLocked, 2000);

        console.log(`OTP session endSession called`);
    }

    returnToClientApplicationWithError(error, errorDescription) {
        const url = this.clientContext['cookieService'].get('redirect_uri');
        console.log('redirect_uri : ', url);
        let state = this.clientContext['cookieService'].get('state');
        console.log('state : ', state);
        let appendChar = '?';
        if (url.includes('?'))
        {
           appendChar = '&';
        }
        window.location.href = url + appendChar + 'error=' + error
           + '&error_description=' +  errorDescription
           + '&lang=' + this.clientContext['cookieService'].get('lang')
           + '&state=' + state;
        return;
      }

    /** Target Selection */

    private renderTargetsComponent = () => {
        this.viewContainerRef.clear();
        const factory: ComponentFactory<OTPDeviceList> = this.resolver.resolveComponentFactory(OTPDeviceList);
        this.otpDeviceListRef = this.viewContainerRef.createComponent(factory);
        this.otpDeviceListRef.instance.setTargets(this.targets);
        this.otpDeviceListRef.instance.onTargetSelected = this.onTargetSelected;
        this.otpDeviceListRef.instance.onCancelSession = this.onCancelSession;
    }

    private renderConfirmChangeUsernameComponent = () => {
        this.viewContainerRef.clear();
        const factory: ComponentFactory<ConfirmChangeUsernameComponent> =
        this.resolver.resolveComponentFactory(ConfirmChangeUsernameComponent);
        this.confirmChangeUsernameComponent = this.viewContainerRef.createComponent(factory);
        this.confirmChangeUsernameComponent.instance.setTargets(this.targets);
        this.confirmChangeUsernameComponent.instance.onTargetSelected = this.onTargetSelected;
    }

    private renderConfirmRecoveryNumberComponent = () => {
        this.viewContainerRef.clear();
        const factory: ComponentFactory<ConfirmRecoveryNumberComponent> =
        this.resolver.resolveComponentFactory(ConfirmRecoveryNumberComponent);
        this.confirmRecoveryNumberComponent = this.viewContainerRef.createComponent(factory);
        this.confirmRecoveryNumberComponent.instance.setTargets(this.targets);
        this.confirmRecoveryNumberComponent.instance.onTargetSelected = this.onTargetSelected;
    }

    private onTargetSelected = (target: com.ts.mobile.sdk.AuthenticatorTarget): void => {
        const input = com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createTargetSelectionRequest(target);
        const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(input);
        this.currentHandler(response);
    }

    private onCancelSession = (): void => {
        const controlRequest = com.ts.mobile.sdk.ControlRequest.create(com.ts.mobile.sdk.ControlRequestType.CancelAuthenticator);
        const response = com.ts.mobile.sdk.InputOrControlResponse.createControlResponse(controlRequest);
        this.currentHandler(response);
    }

    /** Waiting for OTP input */

    private renderWaitingForInput = (format: com.ts.mobile.sdk.OtpFormat | null, target: com.ts.mobile.sdk.OtpTarget | null) => {
        this.viewContainerRef.clear();
        const optLength = (format as any)._length;

        const factory: ComponentFactory<OTPWaitingForInput> = this.resolver.resolveComponentFactory(OTPWaitingForInput);
        this.otpWaitingForInputRef = this.viewContainerRef.createComponent(factory);
        this.otpWaitingForInputRef.instance.setWaitingInfo(optLength, target.getDescription());
        this.otpWaitingForInputRef.instance.onSubmitCode = this.submitOTPCode;
        this.otpWaitingForInputRef.instance.onResend = this.resendOTPCode;
        this.otpWaitingForInputRef.instance.selectedTargetType = target;
        this.otpWaitingForInputRef.instance.targets = this.targets;
        this.otpWaitingForInputRef.instance.onTargetSelected = this.onTargetSelected;
        this.otpWaitingForInputRef.instance.onChangeEmail = this.onChangeEmailSubmit.bind(this);
    }

    private renderChangeUsernameOtpInputComponent =
    (format: com.ts.mobile.sdk.OtpFormat | null, target: com.ts.mobile.sdk.OtpTarget | null) => {
        this.viewContainerRef.clear();
        const optLength = (format as any)._length;

        const factory: ComponentFactory<OtpConfirmationComponent> = this.resolver.resolveComponentFactory(OtpConfirmationComponent);
        this.changeUsernameOtpInputComponent = this.viewContainerRef.createComponent(factory);

        this.changeUsernameOtpInputComponent.instance.email = this.email;
        this.changeUsernameOtpInputComponent.instance.mobile = this.mobile;
        this.changeUsernameOtpInputComponent.instance.setWaitingInfo(optLength, target.getDescription());
        this.changeUsernameOtpInputComponent.instance.onSubmitCode = this.submitOTPCode;
        this.changeUsernameOtpInputComponent.instance.onResend = this.resendOTPCode;

        this.changeUsernameOtpInputComponent.instance.onChangeUserEmail = this.onChangeUserUsernameClick.bind(this);
    }

    private renderRecoveryNumberOtpInputComponent =
    (format: com.ts.mobile.sdk.OtpFormat | null, target: com.ts.mobile.sdk.OtpTarget | null) => {
        this.viewContainerRef.clear();
        const optLength = (format as any)._length;

        const factory: ComponentFactory<RecoveryNumberOtpConfirmationComponent> =
         this.resolver.resolveComponentFactory(RecoveryNumberOtpConfirmationComponent);
        this.recoveryNumberOtpConfirmationComponent = this.viewContainerRef.createComponent(factory);

        this.recoveryNumberOtpConfirmationComponent.instance.email = this.email;
        this.recoveryNumberOtpConfirmationComponent.instance.mobile = this.mobile;
        this.recoveryNumberOtpConfirmationComponent.instance.setWaitingInfo(optLength, target.getDescription());
        this.recoveryNumberOtpConfirmationComponent.instance.onSubmitCode = this.submitOTPCode;
        this.recoveryNumberOtpConfirmationComponent.instance.onResend = this.resendOTPCode;

        this.recoveryNumberOtpConfirmationComponent.instance.onChangeRecoveryNumber = this.onChangeRecoveryNumberClick.bind(this);

    }

    private submitOTPCode = (code: string) => {
        console.log(`Inside otp submit:${code}`);
        const input = com.ts.mobile.sdk.OtpInputOtpSubmission.createOtpSubmission(code);

        const inputTargetBased = com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createAuthenticatorInput(input);
        const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputTargetBased);

        this.currentHandler(response);
    }

    private resendOTPCode = () => {
    console.log('OTPWaitingForInput - OnSubmit');
    // if (this.otpInputValue.length === 0) { return alert('Please type a valid otp') }
    const resend = com.ts.mobile.sdk.OtpInputRequestResend.createOtpResendRequest();
    const inputTargetBased = com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createAuthenticatorInput(resend);
    this.currentHandler(com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputTargetBased));
    // this.onSubmitCode(this.otpInputValue);
    console.log(`OTPWaitingForInput - OnSubmit:end`);
    }

    private onChangeRecoveryNumberClick(){

        const self = this;
        this.clientContext['cookieService'].set('OTPERROR', '', undefined, undefined, null, true, 'None');
        const changeNumberEscapeOption = self.actionContext.getEscapeOptions()[0];
        if (!changeNumberEscapeOption) {
            console.error(`unable to find a "change_number" escape option in actionContext.escapeOptions`);
        }
        else{
             self.currentHandler(com.ts.mobile.sdk.InputOrControlResponse.createEscapeResponse(changeNumberEscapeOption, new Object()));
        }
    }

    private onChangeUserUsernameClick(){
        const self = this;
        this.clientContext['cookieService'].set('OTPERROR', '', undefined, undefined, null, true, 'None');
        const changeUsernameEscapeOption = self.actionContext.getEscapeOptions()[0];
        if (!changeUsernameEscapeOption) {
            console.error(`unable to find a "change_username" escape option in actionContext.escapeOptions`);
        }
        else{
             self.currentHandler(com.ts.mobile.sdk.InputOrControlResponse.createEscapeResponse(changeUsernameEscapeOption, new Object()));
        }
    }

    private renderOTPComponent(profilePage: string, errorMessage: string){
        if (profilePage === 'recoveryNumber'){
            this.recoveryNumberOtpConfirmationComponent.instance.setErrorCode(errorMessage);
        }
        else if (profilePage === 'changeUsername'){
            this.changeUsernameOtpInputComponent.instance.setErrorCode(errorMessage);
        }
        else{
            this.otpWaitingForInputRef.instance.setErrorCode(errorMessage);
        }
    }

    private onChangeEmailSubmit(){
        const url = this.clientContext['redirect_uri'];
        const state = this.clientContext['state'];
        const errorCode  = 'register';
        let appendChar = '?';
        if (url.includes('?'))
        {
           appendChar = '&';
        }
        window.location.href = url + appendChar + 'error=' + errorCode + '&error_description=register'
        + '&lang=' + this.clientContext['cookieService'].get('lang')
        + '&state=' + state;
      }
}

export default OTPSession;
