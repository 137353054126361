import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HeaderState } from './header.state';

@Injectable({ providedIn: 'root' })
export class HeaderStateService {
  private headerStore$: BehaviorSubject<HeaderState> = new BehaviorSubject(new HeaderState());

  constructor() {
  }

  currentState(): HeaderState {
    return this.headerStore$.getValue();
  }

  setHeaderState(headerState: HeaderState) {
    this.headerStore$.next(headerState);
  }

  get headerState$() {
    return this.headerStore$.asObservable();
  }
}
