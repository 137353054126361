import { Component, OnInit } from '@angular/core';
import { GlobalStateService } from 'src/app/shared/global-state.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';


interface FormattedOTPTarget {
    channel: string;
    targetDescription: string;
    title: string;
    order: number;
    text: string;
}

@Component({
    selector: 'otp-device-list',
    templateUrl: './otpDeviceList.component.html',
    styleUrls: ['./otpDeviceList.component.sass']
})
export class OTPDeviceList implements OnInit {

    targets: Array<com.ts.mobile.sdk.AuthenticatorTarget>;
    formattedTargets: FormattedOTPTarget[] = [];
    onTargetSelected: (target: com.ts.mobile.sdk.AuthenticatorTarget) => void;
    onCancelSession: () => void;
    targetsCount = 0;
    error = '';
    track = {
      otpMethodSelection: {
        pageView: true,
        isModalPopup: false,
        mappingKey: 'otp-method-selection',
      }
    };

    brand = '';
    hideCtn = false;
    hideEmail = false;

    constructor(
        private readonly globalStateService: GlobalStateService,
        private readonly translateService: TranslateService,
        private readonly titleService: Title,
        private readonly cookieService: CookieService
        ) {
        this.cookieService.delete('switched');
    }

    ngOnInit() {
        this.globalStateService.setHideLangToggle(false);
        if (!this.cookieService.check('OTPERROR'))
        {
            this.cookieService.set('OTPERROR','',undefined, undefined, null, true, 'None'); // Initialize
        }

        this.error = this.cookieService.get('OTPERROR');

        let client = this.cookieService.get('brand');
        const brand = this.cookieService.get('client');

        if (brand === 'media'){
          client = 'media';
        }

        const regFlow = this.cookieService.get('regFlow');


        if (this.cookieService.get('title') === 'forgotpassword')
        {
          this.titleService.setTitle(
            this.translateService.instant(client + '.resetPassword.browserTitle')
          );
        }
        else if (this.cookieService.get('title') === 'forgotusername')
        {
          this.titleService.setTitle(
            this.translateService.instant(client + '.forgotUsername.browserTitle')
          );
        }
        else if (regFlow === 'SELF_REG' || regFlow === 'create'){
          this.titleService.setTitle(
            this.translateService.instant(client + '.registration.browserTitle')
          );
        }
        else {
          this.titleService.setTitle(
            this.translateService.instant(client + '.login.browserTitle')
          );
        }

        this.cookieService.delete('timer');
    }

    public setTargets = (targets: Array<com.ts.mobile.sdk.AuthenticatorTarget>) => {
        this.targets = targets;
        this.formatTargets();
    }

    resetErrorMessage(): void {
        this.error = '';
      }

    setErrorCode(errCode) {
        this.error = errCode;
    }

    public chooseOTPTarget = (targetIndex: number) => {
      if (this.onTargetSelected) {

          if(this.targetsCount === 1){
            this.onTargetSelected(this.targets[0]);
          }
          else{
            this.onTargetSelected(this.targets[targetIndex]);
          }
        }
    }

    private formatTargets = (): void => {
        const formatted: FormattedOTPTarget[] = [];
        this.brand = this.cookieService.get('client');

        this.targets.forEach((target: com.ts.mobile.sdk.OtpTarget) => {
            formatted.push({
                channel: this.getChannelName(target.getChannel()),
                targetDescription: target.getDescription(),
                title: this.getChannelName(target.getChannel()).toLocaleLowerCase() === 'sms'
                        ? 'rogers.otpDeviceList.accessibility.sms'
                        : 'rogers.otpDeviceList.accessibility.email',
                order: this.getChannelName(target.getChannel()).toLocaleLowerCase() === 'email'
                        ? 0 : 1,
                text: this.getChannelName(target.getChannel()).toLocaleLowerCase() === 'email'
                        ? 'rogers.otpDeviceList.channels.email'
                        : 'rogers.otpDeviceList.channels.sms'
            });
        });
        this.formattedTargets = formatted.filter(i => i.targetDescription !== 'null')
                                    .sort((a, b) => b.channel.localeCompare(a.channel));

        this.targetsCount = this.formattedTargets.length;

        if (this.targetsCount === 1){
          if (this.formattedTargets.find(i => i.channel.toLocaleLowerCase()  === 'sms')) {
            this.hideCtn = true;
            this.hideEmail = false;
          }
          else{
            this.hideCtn = false;
            this.hideEmail = true;
          }
        }
        else{
          this.hideCtn = false;
          this.hideEmail = true;
        }

        if (this.brand === 'yahoo' && formatted){
        this.hideCtn = true;
        this.hideEmail = true;
        this.formattedTargets = formatted.filter(i => i.channel.toLocaleLowerCase()  !== 'email')
                                    .sort((a, b) => b.channel.localeCompare(a.channel));
        }
    }

    private getChannelName = (channel: com.ts.mobile.sdk.OtpChannel): string => {
        switch (channel) {
            case 1: return 'Sms';
            case 2: return 'Email';
            case 3: return 'PushNotification';
            case 4: return 'VoiceCall';
            default: return 'Unknown';
        }
    }
}
