import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ConfigurationService } from 'src/app/shared/configuration.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, AfterViewInit, OnDestroy {

  private readonly windowObj: any;
  code = '';
  username: string;
  onPasswordSubmit: (code: string) => void;
  onChangePasswordSkipClick: () => void;
  errCode: string;


  @ViewChild('currentPasswordFormRef', { static: true }) currentPasswordFormRef: ElementRef;
  currentPasswordForm = new FormGroup(
    {
      currentPassword: new FormControl('', [Validators.required])
    },
    { updateOn: 'submit' }
  );

  @ViewChild('passwordInput') passwordInput: ElementRef<HTMLInputElement>;
  @ViewChild('continueBtn') continueBtn: ElementRef;
  formSubmitted = false;
  isInProgress = false;
  showNewPassword = true;
  passwordError = false;
  errorCode = '';
  passwordFormErrorAlert = false;

  track = {
    changePasswordInputPageview: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'change-password-input',
    },
    changePasswordInputErrors: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'change-password-errors',
      category: 'self serve',
      type: '',
      code: '',
      API: this.configurationService.config.analyticsAPIUrl,
      field: ''
    },
    changePasswordInputFormError: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'change-password-errors',
      category: 'self serve',
      type: 'form',
      code: 'UE034',
      API: '',
      field: 'password'
    },
  };

  constructor(
    private readonly configurationService: ConfigurationService,
    private readonly translate: TranslateService,
    private readonly titleService: Title,
    private readonly formBuilder: FormBuilder,
    private readonly cookieService: CookieService
  ) {
    this.windowObj = !!window.frameElement
      ? (window.parent as any)
      : (window as any);
  }

  ngOnInit(): void {
    let client = this.cookieService.get('brand');
    const brand = this.cookieService.get('client');

    if (brand === 'media'){
      client = 'media';
    }

    var element = document.getElementById("transmitContainer");

    element.classList.add("hideTransmitContainer");
    this.translate.onLangChange
    .pipe(untilDestroyed(this))
    .subscribe((langEvent: LangChangeEvent) => {
      this.titleService.setTitle(
        this.translate.instant(client + '.login.browserTitle')
      );
    });
    setTimeout(() => {
    this.titleService.setTitle(
      this.translate.instant(client + '.login.browserTitle')
    );
  }, 500);

}

validateAndSubmit(){
  if (this.code === '' || this.code === null) {
    this.passwordError = true;
  }
  else{
    this.passwordError = false;
  }
  if (this.passwordError) {
    return;
  }
  this.isInProgress = true;
  this.onPasswordSubmit(this.code);
}

keyDownEnter(event: any) {
  event.preventDefault();
  this.continueBtn.nativeElement.click();
}

ngAfterViewInit(): void {
  setTimeout(_ => {
    this.passwordInput.nativeElement.focus();
  }, 500);
}

onChangePasswordSkip(event){
  event.preventDefault();
  this.onChangePasswordSkipClick();
}

setErrorCode(errorCode){
  this.errorCode = errorCode;
  if (this.errorCode !== '' && this.errorCode !== undefined){
    const pwd = this.cookieService.get('errorPwdCode');

    this.currentPasswordForm.controls['currentPassword'].setValue(pwd);

    this.cookieService.delete('errorPwdCode');
    this.passwordFormErrorAlert = true;

   // this.continueBtn.nativeElement.click();
  }
  else{
    this.passwordFormErrorAlert = false;
  }

}

resetErrorCode() {
  this.passwordFormErrorAlert =  false;
}

ngOnDestroy(): void {}


}
