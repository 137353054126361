import { ComponentFactory, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { ChangePasswordComponent } from './change-password.component';

export class ChangePasswordFormSession {
  clientContext: any;
  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;
  ChangePasswordComponent: ComponentRef<ChangePasswordComponent>;


  actionContext: any;
  submitHandler: any;
  mode: any;
  title: string;
  username: string;

  constructor(title, username){
      this.title = title;
      this.username = username;
      console.log(`Constructor title ${this.title}`);
      console.log(`Constructor username ${this.username}`);
  }
  // this.submitHandler = null;

  startSession(
    description: com.ts.mobile.sdk.AuthenticatorDescription,
    mode: com.ts.mobile.sdk.AuthenticatorSessionMode,
    actionContext: com.ts.mobile.sdk.PolicyAction | null,
    clientContext: object | null
  ): void {
    console.log('Started change passwordTs session');
    this.actionContext = actionContext;
    this.clientContext = clientContext;
    this.mode = mode;

  }

  endSession(): void {
    console.log('Change passwordTs session ended');
    this.clientContext['cookieService'].delete('errorPwdCode');
  }

  promiseInput(): Promise<com.ts.mobile.sdk.InputOrControlResponse<ResponseType>>{
    const self = this;
    self.resolver = (self.clientContext as any).resolver;
    self.viewContainerRef = (self.clientContext as any).viewContainerRef;
    self.viewContainerRef.clear();

    const factory: ComponentFactory<ChangePasswordComponent> = self.resolver.resolveComponentFactory(ChangePasswordComponent);
    self.ChangePasswordComponent = self.viewContainerRef.createComponent(factory);
    self.ChangePasswordComponent.instance.onPasswordSubmit = self.onCurrentPasswordSubmit;

    self.ChangePasswordComponent.instance.onChangePasswordSkipClick = self.onChangePasswordSkipSubmit.bind(self);

    return new Promise((resolve, reject) => {
      self.submitHandler = (response) => {
        resolve(response);
      };
    });
  }

  promiseRecoveryForError(
    error: com.ts.mobile.sdk.AuthenticationError,
    validRecoveries: Array<com.ts.mobile.sdk.AuthenticationErrorRecovery>,
    defaultRecovery: com.ts.mobile.sdk.AuthenticationErrorRecovery
  ): Promise<com.ts.mobile.sdk.AuthenticationErrorRecovery> {
    return new Promise((resolve, reject) => {

      console.log(`change passwordTs was called with error: ${error}`);
      const newLocal = 'additional_data';
      const errorCode = error.getData()[newLocal].code;

      if (errorCode !== '' && errorCode !== null && errorCode !== undefined){ // incorrect password
        setTimeout(() => this.ChangePasswordComponent.instance.setErrorCode(errorCode), 100);
        resolve(com.ts.mobile.sdk.AuthenticationErrorRecovery.RetryAuthenticator);
      }
    });
  }

  onError(payload: object): void {}

  changeSessionModeToRegistrationAfterExpiration() {
    console.log('Password Expired.');
  }

  onCurrentPasswordSubmit = (code: string) => {
    const inputResponse = com.ts.mobile.sdk.PasswordInput.create(code);
    const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputResponse);
    
    this.clientContext['cookieService'].set('errorPwdCode', code, undefined, undefined, null, true, 'None');
    this.submitHandler(response);
  }

  onChangePasswordSkipSubmit = () => {
    const url = this.clientContext['redirect_uri'];
    const state = this.clientContext['state'];
    const errorCode  = 'cancel_password';
    let appendChar = '?';
    if (url.includes('?'))
    {
       appendChar = '&';
    }
    window.location.href = url + appendChar + 'error=' + errorCode + '&error_description=cancel_password' 
    + '&lang=' + this.clientContext['cookieService'].get('lang')
    + '&state=' + state;
  }

}
interface ActionsInPayload {
  [key: string]: any;
}

interface JourneyPayload {
  actions: ActionsInPayload[];
  stat: string;
  code: string;
  flow: string;
}
export default ChangePasswordFormSession;
