import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { IShieldContext } from './verification-context.interface';
import { IAccount } from '@rogers/analytics';
import { SESSION_STORAGE } from './session-storage.token';
import { ConfigurationService } from './configuration.service';
import { WINDOW } from './window.token';

export enum Flow {
  Login = 'Login',
  ForgotUsername = 'forgotUsername',
  ResetPassword = 'resetPassword',
  Registration = 'registration',
  Shield = 'shield',
  Loading = 'loading',
  AutoRegCreateLink = 'auto-reg-CREATE_LINK',
  AutoRegResetLink = 'auto-reg-RESET_LINK',
  AutoRegReset = 'auto-reg-RESET',
  AutoRegError = 'auto-reg-Error',
  SetRecoveryNumber = 'set-recovery-number',
  ChangeUsername = 'change-username',
  ChangeUsernameByToken = 'change-username-token',
  AccountRecovery = 'account-recovery'
}

export enum FidoProfileType {
  AccountHolder = 'Account Holder',
  Subscriber = 'Subscriber',
  Prepaid = 'Prepaid',
  GroupIDLogin = 'Group ID login'
}

export interface User {
  userName: string;
  userEmail: string;
  userType: string;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalStateService {
  private readonly _language$ = new BehaviorSubject<string>('en');
  private readonly _province$ = new BehaviorSubject<string>('ON');
  private readonly _email$ = new BehaviorSubject<string>(null);
  private readonly _flow$ = new BehaviorSubject<Flow>(null);
  private readonly _tokenFromLogin = new BehaviorSubject<string>(null);
  private readonly _isRequiredFullpageExperience = new BehaviorSubject<boolean>(
    false
  );
  private readonly _fidoProfileType$ = new BehaviorSubject<FidoProfileType>(
    null
  );
  private readonly _shieldContextInfo$ = new BehaviorSubject<any>(null); //TODO update Model based on BE Contract
  private readonly _resetModalPage: Subject<void> = new BehaviorSubject<void>(
    null
  );
  closeModalResetPage$: Observable<void> = this._resetModalPage.asObservable();
  private readonly _hideLangToggle = new BehaviorSubject<boolean>(
    false
  );

  private readonly _account$: BehaviorSubject<IAccount> =
    new BehaviorSubject<IAccount>(null);

  account$: Observable<any> = this._account$.asObservable();
  currentLanguage$ = this._language$.asObservable();
  currentProvince$ = this._province$.asObservable();
  currentFlow$ = this._flow$.asObservable();
  fidoProfileType$ = this._fidoProfileType$.asObservable();
  email$ = this._email$.asObservable();

  constructor(
    private readonly cookieService: CookieService,
    @Inject(SESSION_STORAGE) private sessionStorage: Storage,
    @Inject(WINDOW) private readonly window: Window,
    private readonly translateService: TranslateService,
    private readonly configurationService: ConfigurationService
  ) {}

  updateState() {
    this._language$.next(this.translateService.currentLang);
    const province = this.cookieService.get('province');
    if (province) {
      this._province$.next(province);
    }
  }

  isFido() {
    return this.cookieService.get('brand') === 'fido';
    }

  setLanguageToggle(): void {
    const languageToUse = this.getLanguageToggle();
    this.translateService.use(languageToUse);
    this._language$.next(languageToUse);
  }

  getLanguageToggle(): string {
    const value = this._language$.value;

    if ( value === 'fr') {
      return 'en';
    }
    return 'fr';
  }

  getCurrentLangBehaviorSubject(): BehaviorSubject<string> {
    return this._language$;
  }

  setCurrentLang(lang){
    this.translateService.currentLang = lang;
    this._language$.next(this.translateService.currentLang);
  }

  setEmail(email: string) {
    this._email$.next(email);
  }

  setCurrentFlow(flow: Flow) {
    this._flow$.next(flow);
  }

  getCurrentFlow() {
    return this._flow$.value;
  }

  setLoginFromToken(token: string) {
    this._tokenFromLogin.next(token);
  }

  getLoginFromToken() {
    return this._tokenFromLogin.value;
  }

  setFidoProfileTypeByCode(typeCode) {
    let profileType;
    switch (typeCode) {
      case '1':
        profileType = FidoProfileType.AccountHolder;
        break;
      case '2':
        profileType = FidoProfileType.Subscriber;
        break;
      case '3':
        profileType = FidoProfileType.Prepaid;
        break;
      case '4':
        profileType = FidoProfileType.GroupIDLogin;
        break;
    }
    this._fidoProfileType$.next(profileType);
  }

  getFidoProfileType() {
    return this._fidoProfileType$.value;
  }

  revertModalPage() {
    this._resetModalPage.next();
  }

  setShieldOrRecoveryContextInfo(contextInfo: IShieldContext) {
    this._shieldContextInfo$.next(contextInfo);
  }

  getShieldOrRecoveryContextInfo() {
    return this._shieldContextInfo$.value;
  }

  setAccount(userName: string, includeCtnBan, includeType = false) {
    // These changes are required for Analytics as per Kartik
    let CTN = null;
    let BAN = null;
    try {
      const sessionStorageADLObject = JSON.parse(
        this.sessionStorage.getItem('__adl')
      );
      if (includeCtnBan) {
        CTN =
          !!sessionStorageADLObject && sessionStorageADLObject['CTN']
            ? sessionStorageADLObject['CTN']
            : this.cookieService.get('aam_dpid943');
        BAN =
          !!sessionStorageADLObject && sessionStorageADLObject['BAN']
            ? sessionStorageADLObject['BAN']
            : this.cookieService.get('aam_dpid942');
      }
      const payload = {
        BAN: BAN ? BAN : '',
        CTN: CTN ? CTN : '',
        type: includeType
          ? JSON.parse(this.cookieService.get('_analytics')).accType
          : null,
        userName,
        userType: 'consumer'
      };
      this._account$.next(payload);
    } catch (e) {
      console.error(e);
    }
  }

  setApplicationLanguage() {
    return new Promise((resolve, _reject) => {
      const currentLang = this.determineLanguage();
      this.translateService.addLangs(['en', 'fr']);
      this.translateService.setDefaultLang(currentLang);
      this.translateService.use(currentLang);
      this.updateState();
      resolve(true);
    });
  }

  isDeeplink() {
    let deeplinkUrl;
    if (this.isFido()) {
      // TODO: Some pages are setting a cookie named 'cookieName'
      // instead of 'deepJSON' for deep-linking. To be on the safe side
      // on this release, we still support "cookieName" cookie as well but 
      // in the next release we should remove it and ask other teams to use the right cookie.
      deeplinkUrl =
        this.cookieService.get('cookieName') ||
        this.cookieService.get('deepJSON');
      return (
        deeplinkUrl &&
        deeplinkUrl.indexOf('wireless') === -1 &&
        (deeplinkUrl.indexOf('pages/#') === -1 ||
          deeplinkUrl.indexOf('referer=') > 0 ||
          deeplinkUrl.indexOf('lithium=') > 0)
      );
    } else {
      deeplinkUrl = this.sessionStorage.getItem('ute.rci.deep.link.url');
      return (
        deeplinkUrl &&
        deeplinkUrl.length > 0 &&
        (deeplinkUrl.indexOf('/web/totes/#') === -1 ||
          deeplinkUrl.indexOf('TARGET') > 0 ||
          deeplinkUrl.indexOf('referer') > 0 ||
          deeplinkUrl.indexOf('sourceid') > 0 ||
          deeplinkUrl.indexOf('wcoc') > 0)
      );
    }
  }

  private determineLanguage() {
    let language = this.isEnglishOrFrench('en');
    if (!language) {
      language = this.isEnglishOrFrench('fr');
    }

    const languageFromCookie = this.cookieService.get('language');

    language =
      language ||
      languageFromCookie ||
      (navigator.language.indexOf('fr') > -1 ? 'fr' : 'en') ||
      'en';
    return language;
  }

  private isEnglishOrFrench(languageToCheck) {
    const path = this.window.location.href;
    if (
      path.indexOf(`/${languageToCheck}`) > -1 ||
      path.indexOf(`lang=${languageToCheck}`) > -1 ||
      path.indexOf(`setLanguage=${languageToCheck}`) > -1
    ) {
      return languageToCheck;
    }
    return '';
  }

  findUrlMatchForFullPage(): boolean {
    const fidoUrlToDisplayIFrameWithoutCLoseBtn = [
      '/self-serve/signin',
      '/pages/#/signin',
      '/phones/?flowType=aal'
    ];
    const rogersUrlToDisplayIFrameWithoutCLoseBtn = [
      '/consumer/self-serve/signin',
      '/web/consumer'
    ];
    const urlVariable = this.isFido()
      ? fidoUrlToDisplayIFrameWithoutCLoseBtn
      : rogersUrlToDisplayIFrameWithoutCLoseBtn;
    const path =
      this.window.location !== this.window.parent.location
        ? this.window.parent.location.href
        : this.window.location.href;
    const isThereUrlMatch = !!urlVariable.find(
      eachVal => path.indexOf(eachVal) > -1
    );
    this._isRequiredFullpageExperience.next(isThereUrlMatch);
    return isThereUrlMatch;
  }

  get isRequiredFullpageExperience(): Observable<boolean> {
    return this._isRequiredFullpageExperience;
  }

  get isRemindMeCookieNameAvailable(): boolean {
    return this.cookieService.check(
      this.configurationService.config.remindMeCookieName
    );
  }

  get isRemindChangeUsernameCookieNameAvailable(): boolean {
    return this.cookieService.check(
      this.configurationService.config.remindChangeUsernameCookieName
    );
  }

  get isOpenedPageFromProfileAndSettings(): boolean {
    const fidoProfileAndSettingsUrl = '/view-profile';
    const rogersProfileAndSettingsUrl = '/self-serve/profile';
    const urlVariable = this.isFido()
      ? fidoProfileAndSettingsUrl
      : rogersProfileAndSettingsUrl;
    if (this.window && this.window.frameElement) {
      return this.window.parent.location.href.indexOf(urlVariable) > -1;
    }
    return false;
  }

  get hideLangToggle(): Observable<boolean> {
    return this._hideLangToggle;
  }

  setHideLangToggle(value: boolean) {
    this._hideLangToggle.next(value);
  }

}
