import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-security-question',
  templateUrl: './security-question.component.html',
  styleUrls: ['./security-question.component.scss']
})
export class SecurityQuestionComponent implements OnInit {

  securityQuestion: null;
  securityQuestionForm: FormGroup;

  errorCode = '';

  onSecurityAnwerSubmit: (answer: string) => void;
  onCancelSubmit: () => void;

  constructor(private readonly formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.securityQuestionForm = this.formBuilder.group({
      answer : ['', Validators.required]
    });

  }

  onSubmit(){
    const ansVal = this.securityQuestionForm.value.answer;
    this.onSecurityAnwerSubmit(ansVal);
  }

  onCancel(){
    this.onCancelSubmit();
  }

  setErrorCode(code){
    this.errorCode = code;
  }

  resetErrorMessage(){
    this.errorCode = '';
  }

}

