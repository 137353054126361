<div [ngClass]="{'hideSPA': showSPA == false}" class="d-flex flex-column h-100" [attr.appVersion]="appVersion" role="main">
  <div
    class=" d-flex justify-content-center flex-grow-1 flex-shrink-1 flex-basis-auto mt-76"
    [ngClass]="languageClass"
  >

    <div [ngClass]="(headerState$ | async)?.class" class="app-container w-100 ">

      <div class="d-flex flex-column h-100 ">
        <div class="d-flex justify-content-center flex-grow-1 flex-shrink-1 flex-basis-auto mt-76">
          <div class="app-container w-100 ">
            <div
              class="w-100 w-sm-75 w-md-50 m-sm-auto m-sm-12 border mainWidth ds-corners ds-bgcolor-snow dam-maxheight"
            >
              <div class="p-24 pr-sm-96 pl-sm-96 pb-sm-32" [ngClass]="addCss">
                <div class="modal-close-row">
                  <div *ngIf="isIframe" class="modal-close">
                    <button ds-button="" variant="icon" class="ds-modal__closeButton ds-button ds-corners ds-pointer 
                        text-center mw-100 -icon d-inline-flex justify-content-center align-items-center 
                        -large" [attr.title]="
                        'rogers.modalCoseBtn' | translate"
                        tabindex="0" style="transform: scale(1);"
                        (click)="closeModal()">                        
                        <ds-icon 
                          class="-size-24"
                          name="close"                                           
                          caption="Attention"> 
                        </ds-icon>                        
                    </button>                
                  </div>
                </div>
                <div *ngIf="showHeaderBackground"
                  class="pb-24 pb-sm-16 d-flex flex-row"
                  [ngClass]="
                    showLogo
                      ? 'justify-content-between align-items-center logoheader'
                      : 'justify-content-end'
                   "
                >
                  <ng-container *ngIf="isLogoLinked; then linkedLogo; else unlinkedLogo"></ng-container>
                  <ng-template #linkedLogo>
                    <a
                      *ngIf="showLogo"
                      ds-link
                      variant="icon"
                      [href]="homeLinkUrl"
                      [target]="urlTarget"
                      [title]="homeLinkTitle | translate"
                    >
                      <img
                        [src]="imgSrc"
                        class="logoImage"
                        [alt]="logoAlt | translate"
                      />
                    </a>
                  </ng-template>
                  <ng-template #unlinkedLogo>
                    <img
                      *ngIf="showLogo"                  
                      [src]="imgSrc"
                      class="logoImage"
                      [alt]="logoAlt | translate"
                    />
                  </ng-template>

                  <div *ngIf="showLang">
                    <button
                      ds-button
                      variant="tertiary"
                      *ngIf="(!isIframe) && !hideLangToggle"
                      [attr.title]="'common.' + toggleLangText | translate"
                      [attr.aria-label]="'common.' + toggleLangText | translate"
                      (click)="languageToggle()"
                    >
                      {{ toggleLangText | uppercase }}
                    </button>
                  </div>
                </div>
                <!--<ng-content></ng-content>-->
              
                <div #transmitContainer id='transmitContainer'></div>
                <!--<router-outlet></router-outlet>-->
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
