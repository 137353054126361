import { ComponentFactory, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from "@angular/core";
import { SetMultiFactorAuthComponent } from "./set-multi-factor-auth.component";


class SetMultiFactorAuthSession implements com.ts.mobile.sdk.UIFormSession{
    clientContext: object;
    actionContext: object;
    formId: string;
    payload: object;

    submitHandler: any;
    submitBlock: (payload: object) => void;
    resolver: ComponentFactoryResolver;
    viewContainerRef: ViewContainerRef;
    setMultiFactorAuthComponentRef: ComponentRef<SetMultiFactorAuthComponent>;

    constructor(formId: string, payload: object) {
        console.log('Inside Multi-factor AuthTs form constructor');
        this.formId = formId;
        this.payload = payload;
    }

    startSession(clientContext: object | null, actionContext: com.ts.mobile.sdk.PolicyAction | null): void {
        console.log('Multi-factor AuthTs Ts session started: ' + this.formId);
        this.clientContext = clientContext;
        this.actionContext = actionContext;
        this.resolver = (clientContext as any).resolver;
        this.viewContainerRef = (clientContext as any).viewContainerRef;
      }

      endSession(): void {
        console.log('Multi-factor AuthTs session ended: ' + this.formId);
      }

      promiseFormInput(): Promise<com.ts.mobile.sdk.FormInput> {
        console.log('Multi-factor AuthTs session Input: ' + this.formId);
        const self = this;
        self.viewContainerRef.clear();
        const factory: ComponentFactory<SetMultiFactorAuthComponent> = self.resolver.resolveComponentFactory(SetMultiFactorAuthComponent);
        self.setMultiFactorAuthComponentRef = self.viewContainerRef.createComponent(factory);

        this.setMultiFactorAuthComponentRef.instance.emailAddress = this.clientContext['email_mfa'];
        this.setMultiFactorAuthComponentRef.instance.recoveryNumber = this.clientContext['mobile_mfa'];
        this.setMultiFactorAuthComponentRef.instance.skip = this.clientContext['skip_mfa'];

        self.setMultiFactorAuthComponentRef.instance.onMFAEnableSubmit = self.onMFASubmit.bind(self);
        self.setMultiFactorAuthComponentRef.instance.onMFASkipSubmit = self.onMFASkipSubmit.bind(self);

        console.log('Multi-factor AuthTs constructor payload:', this.payload);

        const journeyPayload = this.payload as JourneyPayload;
        console.log('Multi-factor AuthTs payload actions using interface', journeyPayload.actions);


        return new Promise((resolve, reject) => {
          self.submitBlock = (payload: object) => {
            try {
              resolve(com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(Object.assign(payload)));
              console.log('Multi-factor AuthTs createFormInputSubmissionRequest SUCCESS payload: ', payload);
              self.submitBlock = null;
            } catch (e) {
              console.log('Multi-factor AuthTs createFormInputSubmissionRequest ERROR payload: ', payload);
              console.error(e);
            }
          };
        });
      }

      onContinue(payload: object): void {
        console.log('Multi-factor AuthTs onContinue for ' + this.formId + ' payload:', payload);
      }

      onError(payload: object): void {
        console.log('Multi-factor AuthTs onError for ' + this.formId + ' payload:', payload);
      }

      private onMFASubmit(payload: object) {
        const data = payload;
        this.submitBlock(data);
      }

      private onMFASkipSubmit(payload: object) {
        const data = payload;
        this.submitBlock(data);
      }
}

interface JourneyPayload {
    actions: ActionsInPayload[];
    stat: string;
    code: string;
    flow: string;
  }

interface ActionsInPayload {
    [key: string]: any;
  }

export default SetMultiFactorAuthSession;
