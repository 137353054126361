import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef, ModalService, ModalComponent as DsModalComponent } from '@rogers/cdk/modal';
import { CookieService } from 'ngx-cookie-service';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { GlobalStateService } from 'src/app/shared/global-state.service';

interface FormattedOTPTarget {
  channel: string;
  targetDescription: string;
}

@Component({
  selector: 'app-confirm-recovery-number',
  templateUrl: './confirm-recovery-number.component.html',
  styleUrls: ['./confirm-recovery-number.component.scss']
})
export class ConfirmRecoveryNumberComponent implements OnInit {

  confirmRecoveryNumberInProgress = false;
  modalRef: ModalRef;
  @Output() back = new EventEmitter<void>();
  @Input() hideBackBtn: boolean;
  @Input() showLogo = true;
  newRecoveryNumber: string;
  changeRecoveryNumberDesc: string;
  logo: string;
  error = '';


  targetsCount = 0;
  targets: Array<com.ts.mobile.sdk.AuthenticatorTarget>;
  formattedTargets: FormattedOTPTarget[] = [];
  onTargetSelected: (target: com.ts.mobile.sdk.AuthenticatorTarget) => void;

  track = {
    changeMobileConfirmPageview: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'change-mobile-confirm',
    },
    changeMobileConfirmErrors: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'change-mobile-confirm',
      category: 'self serve',
      type: '',
      code: '',
      API: this.configurationService.config.analyticsAPIUrl,
      field: ''
    }
  };

  constructor(private readonly configurationService: ConfigurationService,
              private modalService: ModalService,
              private viewContainerRef: ViewContainerRef,
              private readonly translate: TranslateService,
              private readonly globalStateService: GlobalStateService,
              private readonly cookieService: CookieService) { }

  public setTargets = (targets: Array<com.ts.mobile.sdk.AuthenticatorTarget>) => {
    this.targets = targets;
    this.formatTargets();
  }

  resetErrorMessage(): void {
    this.error = '';
  }

  private formatTargets = (): void => {
    const formatted: FormattedOTPTarget[] = [];
    this.targets.forEach((target: com.ts.mobile.sdk.OtpTarget) => {
        formatted.push({
            channel: this.getChannelName(target.getChannel()),
            targetDescription: target.getDescription()
        });
    });
    this.formattedTargets = formatted;
    this.targetsCount = this.formattedTargets.length;
  }

  private getChannelName = (channel: com.ts.mobile.sdk.OtpChannel): string => {
    switch (channel) {
        case 1: return 'Sms';
        case 2: return 'Email';
        case 3: return 'PushNotification';
        case 4: return 'VoiceCall';
        default: return 'Unknown';
    }
  }
  ngOnInit(): void {
    this.globalStateService.setHideLangToggle(false);

    if (!this.cookieService.check('OTPERROR'))
    {
        this.cookieService.set('OTPERROR','',undefined, undefined, null, true, 'None'); // Initialize
    }

    this.error = this.cookieService.get('OTPERROR');

    this.newRecoveryNumber = this.cookieService.get('newRecoveryNumber');
    this.cookieService.delete('mobile_timer');
    this.setTranslatePropertiesByBrand();
  }

  onConfirmSubmit(){
    this.confirmRecoveryNumberInProgress = true;
    this.onTargetSelected(this.targets[0]);
  }

  onBack() {
    this.back.emit();
  }

  openModal(templateRef: TemplateRef<DsModalComponent>) {
    this.modalRef = this.modalService.open(
      templateRef,
      this.viewContainerRef,
      { backdropClick: false }
    );
  }

  closeModal() {
    this.modalRef.close();
  }

  get isLogoLinked() {
    return false;
  }

  get homeLinkUrl() {
    const homeURL = this.configurationService.config.homeURL;
    return homeURL + `?language=${this.translate.currentLang}`;
  }

  get urlTarget() {
    return '_self';
  }

  private setTranslatePropertiesByBrand(){
    const client = this.cookieService.get('client');
    if (client === 'media'){
      this.changeRecoveryNumberDesc = 'media.confirmReoveryNumber.body';
    }
    else if (client === 'fido') {
      this.changeRecoveryNumberDesc = 'fido.confirmReoveryNumber.body';
      this.logo = './idp/__default/assets/images/f-bg-1.png';
    }
    else if (client === 'r4b') {
      if (this.translate.currentLang === 'fr')
      {
        this.logo = './idp/__default/assets/images/r4blogo_fr.png';
      }
      else 
      {
        this.logo = './idp/__default/assets/images/r4blogo_en.png';
      }
    }
    else if (client === 'yahoo') {
      this.logo = './idp/__default/assets/images/yahoo.png';
    }
    else {
      this.changeRecoveryNumberDesc = 'rogers.changeUsername.confirmReoveryNumber.body';
      this.logo = './idp/__default/assets/images/og-image.png';
    }
  }

}
