import {
  AfterViewInit,
  Component,
  ViewContainerRef,
  ViewChild,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Inject,
  OnInit,
  Renderer2,
  OnDestroy, Input, AfterViewChecked
} from '@angular/core';
import { TransmitService, UIContext } from './transmit/transmitService';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { HeaderStateService } from './shared/header-state.service';
import { HeaderState } from './shared/header.state';
import { DOCUMENT } from '@angular/common';
import { ConfigurationService } from './shared/configuration.service';
import { WINDOW } from './shared/window.token';
import { Flow, GlobalStateService } from './shared/global-state.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { OutboundNavigationService } from './shared/outbound-navigation.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { CDKCoreService } from '@rogers/cdk/core';
import { ScssLoaderService } from './shared/scss.loader.service';
import { CommonService } from './shared/common.service';
import { UserMonitoringService } from './shared/user-monitoring.service';
import { CookieService } from 'ngx-cookie-service';
import { Title } from '@angular/platform-browser';


declare var aid: string;
declare var hostname: string;
declare var csmID: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

@Injectable()
export class AppComponent implements AfterViewInit, OnInit, OnDestroy, AfterViewChecked {
  headerState$: Observable<HeaderState>;
  appVersion: string;
  sessionTimeout: number;
  title = 'transmit-main-example';
  transmitService: TransmitService;
  @ViewChild('transmitContainer', { read: ViewContainerRef }) transmitContainer: ViewContainerRef;
  public isLoggedIn = false;
  public currentUserId = '';
  @Input() hideLangToggle = false;
  @Input() showCloseIconBtn = false;
  @Input() showLogo = true;
  isIframe: boolean;
  smallScreens: boolean;
  toggleLangText: string;
  fidoShieldUrls = ['/shield', '/shield-verify'];
  resizeObservable$: Observable<Event>;
  client: string | null;
  url: string = '';
  state: string = '';
  flow: string = '';
  showHeaderBackground: boolean = false;
  showSPA: boolean = true;
  imgSrc = '';
  homeLinkTitle = '';
  logoAlt = '';
  showLang = true;

  aid: string = aid;
  hostname: string = hostname;
  csmID: any = csmID;
  mediaCSSLink = 'https://assets.rogers-assets.com/v2/styles/cdk-media.css';


  constructor(
    @Inject(DOCUMENT) private readonly document: HTMLDocument,
    @Inject(WINDOW) private readonly window: Window,
    private readonly headerStateService: HeaderStateService,
    private readonly renderer: Renderer2,
    private readonly translate: TranslateService,
    private readonly globalStateService: GlobalStateService,
    private readonly configurationService: ConfigurationService,
    private changeDetector: ChangeDetectorRef,
    private resolver: ComponentFactoryResolver,
    private http: HttpClient,
    private readonly router: Router,
    private readonly outboundNavigationService: OutboundNavigationService,
    private coreService: CDKCoreService,
    private scssLoader: ScssLoaderService,
    private commonService: CommonService,
    private readonly bnIdle: BnNgIdleService,
    private usm: UserMonitoringService,
    private cookieService: CookieService,
    private readonly titleService: Title
  ) {
    this.headerState$ = this.headerStateService.headerState$;
    this.appVersion = this.configurationService.config.appVersion;
    this.sessionTimeout = this.configurationService.config.sessionTimeout;

    this.cookieService.delete('lang');
    this.cookieService.delete('brand');
    this.cookieService.delete('flow');

    this.cookieService.delete('OTPERROR');
    translate.addLangs(['en', 'fr']);
    this.cookieService.delete('userEmail');
    this.cookieService.delete('profilePage');

    this.cookieService.delete('errorPwdCode');
    this.cookieService.delete('regFlow');
    this.cookieService.delete('title');
    this.cookieService.delete('register');

    this.cookieService.delete('registerErrorCode');
    this.globalStateService.setHideLangToggle(true);

    this.bnIdle.startWatching(this.sessionTimeout).subscribe((res) => {
       if (res) {
           this.url = this.cookieService.get('redirect_uri');
           this.state = this.cookieService.get('state');
           let appendChar = '?';
           if (this.url.includes('?'))
           {
              appendChar = '&';
           }
           window.location.href = this.url + appendChar + 'error=' + 'UE02'
              + '&error_description=timeout'
              + '&lang=' + this.cookieService.get('lang')
              + '&state=' + this.state;
       }
    });

    this.usm.bootstrap();

  }

  onHandleLogin = (authToken: any): void => {
    this.transmitService.authenticate(authToken)
      .then((results: com.ts.mobile.sdk.AuthenticationResult) => {
        console.log(`Inside authenticate:${results}`);
        this.handleAuthenticationResults(results);
      })
      .catch((error: com.ts.mobile.sdk.AuthenticationError) => {

        alert(`Authenticated failed: ${error.getMessage()}`);
        console.error(error);
      });
  }

  onHandleLogout = (): void => {
    this.transmitService.logout()
      .then(() => {
        this.setAuthenticationState();
      });
  }

  ngOnInit() {
    this.isIframe = false;

    if (window.self !== window.top) {
        this.isIframe = true;
    }

    if (window.location !== window.parent.location) {
      this.isIframe = true;
    }

    if (this.isIframe) {
      // Browser back button functionality when SPA inside iframe
      window.history.pushState(null, document.title, window.location.href);
      window.onpopstate = function(event) {
          console.log('Modal closed');
          this.url = this.cookieService.get('redirect_uri');
          this.state = this.cookieService.get('state');
          let appendChar = '?';
          if (this.url.includes('?'))
          {
             appendChar = '&';
          }
          window.location.href = this.url + appendChar + 'error=' + 'ctnupdateskip'
            + '&error_description=ctnupdateskip'
            + '&lang=' + this.cookieService.get('lang')
            + '&state=' + this.state;
      };
    }
    setTimeout(() => {
      document.documentElement.lang = this.translate.currentLang;
      if (this.transmitService.showSPA)
      {
 
        const themeBrand = this.cookieService.get('brand');
        this.coreService.set({brand: (themeBrand === '' || themeBrand === null) ? 'rogers' : themeBrand });

        this.commonService.changeTitle(this.coreService.brand);
        this.coreService.config$.subscribe((config) => {
          this.cookieService.set('brand', config.brand,undefined, undefined, null, true, 'None');
          this.commonService.changeTitle(config.brand);
        });
        console.log(this.cookieService.get('brand'));

        this.client = this.cookieService.get('client');

        if (this.client === 'media'){
          this.document.body.classList.add('media-client');
        }

        let brand = this.client;
        if (this.client === 'media' || this.client === 'yahoo'  || this.client === 'cbu'){
          brand = 'rogers';
        }
        else if (this.client === 'cpp'){
          brand = 'chatr';
        }
        else{
          brand = this.client;
        }

        console.log('Loading styles:', brand);
        this.loadStyle(`cdk-${brand}.css`, this.client);

        if (this.client === 'fido'){
          if (!this.isIframe) {
            this.renderer.addClass(document.body, 'backgroundImage-f');
          }
          this.imgSrc = './idp/__default/assets/images/f-bg-1.png';
          this.homeLinkTitle = 'fido.homeLinkTitle';
          this.logoAlt = 'fido.logoAlt';
        }
        else if (this.client === 'cbu' || this.client === 'rogers')
        {
          if (!this.isIframe) {
            this.renderer.addClass(document.body, 'backgroundImage');
          }

          this.imgSrc = './idp/__default/assets/images/og-image.png';
          this.homeLinkTitle = 'rogers.homeLinkTitle';
          this.logoAlt = 'rogers.logoAlt';
        }
        else if (this.client === 'yahoo')
        {
          if (!this.isIframe) {
            this.renderer.addClass(document.body, 'backgroundImage');
          }

          this.imgSrc = './idp/__default/assets/images/yahoo.png';
          this.homeLinkTitle = 'yahoo.homeLinkTitle';
          this.logoAlt = 'yahoo.logoAlt';
        }

        else if (this.client === 'media'){
          this.renderer.addClass(document.body, 'backgroundImage-sportnet');
          this.imgSrc = './idp/__default/assets/images/sportnet-logo.svg';
          this.homeLinkTitle = 'media.homeLinkTitle';
          this.logoAlt = 'media.logoAlt';
        }
        else if (this.client === 'r4b'){
          this.renderer.addClass(document.body, 'backgroundImage-r4b');
          if (this.translate.currentLang === 'fr')
          {
            this.imgSrc = './idp/__default/assets/images/r4blogo_fr.png';
          }
          else
          {
            this.imgSrc = './idp/__default/assets/images/r4blogo_en.png';
          }
          this.homeLinkTitle = 'r4b.homeLinkTitle';
          this.logoAlt = 'r4b.logoAlt';
        }
        else if (this.client === 'cpp' || this.client === 'chatr'){
          if (!this.isIframe){
            this.renderer.addClass(document.body, 'backgroundImage');
          }

          this.imgSrc = './idp/__default/assets/images/chatr-logo.png';
          this.homeLinkTitle = 'chatr.homeLinkTitle';
          this.logoAlt = 'chatr.logoAlt';
        }

        this.toggleLangText = this.globalStateService.getLanguageToggle();
        this.globalStateService.hideLangToggle.subscribe((val) => {
          this.showLang = val;
        });

        this.smallScreens = window.innerWidth <= 600;
        // this.isIframe = !!this.window.frameElement;

        if (this.isIframe)
        {
          this.resizeObservable$ = fromEvent(window, 'resize');
          this.resizeObservable$.pipe(untilDestroyed(this)).subscribe(() => {
            this.smallScreens = window.innerWidth <= 600;
          });
        }
      }
      this.setTitle();
    }, 1500);
}
setTitle() {
  let title = '';
  const client = this.cookieService.get('client');
  let currentLang = this.cookieService.get('lang');
  let registerTitle = this.cookieService.get('register');
  console.log(registerTitle);
  title = client + '.login.browserTitle';
  if(client === 'media' && registerTitle ==='create'){
    title = client + '.registration.browserTitle';
  }
  this.titleService.setTitle(
    this.translate.instant(title)
  );
  console.log(this.translate.instant(title));

  this.translate.onLangChange
    .subscribe((langEvent: LangChangeEvent) => {
      this.titleService.setTitle(
        this.translate.instant(title)
      );
      console.log(this.translate.instant(title));
      currentLang = langEvent.lang;
    });
}

  // ***
  // not in scope for now
  // ***
  // onInvokeOTPJourney = (): void => {
  //   this.transmitService.invokeJourney('otp_example', {})
  //     .then((results: com.ts.mobile.sdk.AuthenticationResult) => {
  //       this.transmitService.clearUIHandlerContainer();
  //       console.log('OTP journey completed with success');
  //     })
  //     .catch((error: com.ts.mobile.sdk.AuthenticationError) => {
  //       alert(`Error invoking journey, check console log for more info`);
  //       console.log(error);
  //     });
  // }

   async ngAfterViewInit() {
    const xmsdk: com.ts.mobile.sdk.TransmitSDKXm = (window as any).xmsdk;
    const xmui: com.ts.mobile.sdk.UIHandler = (window as any).xmui;
    const uiContext = this.getUIContext();

    this.transmitService = new TransmitService(xmsdk, xmui, uiContext,
                              this.http, this.translate, this.coreService, this.cookieService);

    this.setCurrentLanguage();
    this.setAuthenticationState();
    const authToken = await this.getAuthToken();
    this.onHandleLogin(authToken.token);
    this.showHeaderBackground = this.transmitService.showSPA;
    this.showSPA = this.transmitService.showSPA;
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  private setCurrentLanguage(){

    const currentLang = this.cookieService.get('lang');
    this.globalStateService.setCurrentLang(currentLang);
  }

  private setAuthenticationState(): void {
    this.isLoggedIn = this.transmitService.isLoggedIn();
    console.log(`inside authentication state:${this.isLoggedIn}`);
    const userId = this.transmitService.getCurrentUserId() || '';
    this.currentUserId = this.uppercase(userId);
  }

  private handleAuthenticationResults(results: com.ts.mobile.sdk.AuthenticationResult): void {
    this.clearUIContainer();
    this.setAuthenticationState();
  }

  private clearUIContainer(): void {
    this.transmitContainer.clear();
    this.transmitContainer.element.nativeElement.innerHTML = '';
  }

  private getUIContext(): UIContext {
    return {
      viewContainerRef: this.transmitContainer,
      resolver: this.resolver
    };
  }

  private uppercase(name: string): string {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }


  private loadStyle(styleName: string, brand: any) {
    console.log('Loading styles:', styleName);
    const head = this.document.getElementsByTagName('head')[0];
    const themeLink = this.document.getElementById('brand-style') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'brand-style';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }

    if (brand === 'media'){
      const mediaThemeLink = this.document.getElementById('media-style') as HTMLLinkElement;
      mediaThemeLink.href = this.mediaCSSLink;
      const val = mediaThemeLink.href;
    }
    console.log('Styles Loaded:', styleName);
  }

  closeModal() {

    console.log('Modal closed');
    this.url = this.cookieService.get('redirect_uri');
    this.state = this.cookieService.get('state');

    this.flow = this.cookieService.get('flow');
    let appendChar = '?';
    if (this.url.includes('?'))
    {
       appendChar = '&';
    }
    if (this.flow === 'update_mfa')
    {
      window.location.href = this.url + appendChar + 'error=' + 'cancel_mfa'
      + '&error_description=User decided not to change MFA factors'
      + '&lang=' + this.cookieService.get('lang')
      + '&state=' + this.state;
    }
    else
    {
      window.location.href = this.url + appendChar + 'error=' + 'ctnupdateskip'
      + '&error_description=ctnupdateskip'
      + '&lang=' + this.cookieService.get('lang')
      + '&state=' + this.state;
    }

  }

  get languageClass() {
    return `lang-${this.translate.currentLang}`;
  }
  // ***
  // The following template fragment and functions have
  // iFrame considerations.  This is used when called externally from another app,
  // this is called 'Shield' in the former DAM code base
  // ***
  // right before the div where "ngClass=addCss" is called:
  // <div class="d-flex flex-row  justify-content-end"
  //                    *ngIf="((isIframe && !hideCloseBtn) || showCloseIconBtn) && !smallScreens">
  //                 <button
  //                   ds-button
  //                   variant="icon"
  //                   (click)="closeModal()"
  //                   [attr.title]="'rogers.closeAlt' | translate"
  //                   [transparent]="true"
  //                   [ngClass]="addBtnCss"
  //                 >
  //                   <ds-icon [name]="'close'"></ds-icon>
  //                 </button>
  //               </div>
  // and after the language toggle:
  //    <button
  //       ds-button
  //       variant="icon"
  //       (click)="closeModal()"
  //       [attr.title]="'rogers.closeAlt' | translate"
  //       [transparent]="true"
  //       *ngIf="((isIframe && !hideCloseBtn) || showCloseIconBtn) && smallScreens"
  //     >
  //       <ds-icon [name]="'close'"></ds-icon>
  //     </button>
  get addCss() {
    return this.isIframe ? null : 'pt-sm-48';
  }

  get addBtnCss() {
    return this.fidoShieldUrls.includes(this.router.url)
      ? 'addFidoBtnCss'
      : null;
  }

  get isLogoLinked() {
    return !this.isIframe;
  }

  get homeLinkUrl() {
    // get logo href based on client. Default to rogers
    this.client = this.cookieService.get('client');

    // default
    let homeURL = this.configurationService.config.homeURL;

    if (this.client === 'fido'){
      homeURL = this.configurationService.config.fidohomeURL;
    }
    else if (this.client === 'yahoo')
    {
      homeURL = this.configurationService.config.yahoohomeURL;
    }
    else if (this.client === 'r4b'){
      homeURL = this.configurationService.config.r4bhomeURL;
    }
    else if (this.client === 'chatr'){
      homeURL = this.configurationService.config.chatrhomeURL;
    }
    else if (this.client === 'media'){
      homeURL = this.cookieService.get('redirect_uri');
      return homeURL;
    }

    return homeURL + `?language=${this.translate.currentLang}`;
  }

  get urlTarget() {
    return '_self';
  }

  languageToggle() {
    this.globalStateService.setLanguageToggle();
    this.toggleLangText = this.globalStateService.getLanguageToggle();

    if (this.toggleLangText === 'fr'){
      this.cookieService.set('lang', 'en', undefined, undefined, null, true, 'None');
    }
    else{
      this.cookieService.set('lang','fr', undefined, undefined, null, true, 'None');
    }
  }

  getAuthToken(){
    const url = hostname + '/api/v2/login/direct/' + aid + '/preauthenticate?csmID=' + csmID;
    return  this.http.get<AuthToken>(url).toPromise();
  }

  ngOnDestroy(): void {}

}

interface AuthToken {
  platform: string;
  token: string;
}
