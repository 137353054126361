<div class="verify-component">
  <ng-container ssTrackEvent [event]="track.otpInputPageView"></ng-container>
<h1 class="text-title-4 mb-8" role="heading">
  <img
    [src]="'idp/__default/assets/images/' + verifyType + '.png'"
    class="mr-16 mr-sm-8 verify-icon"
    alt=""
  />
  <span >{{
    'rogers.' + verifyType + '.mainHeader' | translate
  }}</span>
</h1>
<p
class="text-body-lg mb-24"
[innerHTML]="
  'rogers.' + verifyType + '.mainMessage'
    | translate
      : {
          value: optTargetDescription
        }"
></p>

<form [formGroup]="verifyUserForm" autocomplete="off"
(ngSubmit)="onSubmit()" preventSubmitDirective>

<div class="mb-16 code-input-container">

  <div class="mb-16" *ngIf="(formSubmitted && maxOtpAttemptsTried) || incorrectCode  || otherError">
    <ds-alert
      variant="error"
      [closeLabel]="'rogers.login.popOverClose' | translate"
      (closeClick)="resetErrorMessage()"
      role="alert"
      ssTrackError
      [error]="track.otpInputApiErrors">
      <div class="d-flex flex-column flex-md-row">
        <div class="flex-grow-1 d-flex">
          <ds-icon
            class="mr-8"
            name="exclamation-circle"
            color="error"
            [ariaHidden]="false"
            [attr.title]="'rogers.login.accessibility.error' | translate"
            caption="Attention">
          </ds-icon>
          <div>
            <p *ngIf='maxOtpAttemptsTried'
              damTranslationWithRouting
              class="text-body mb-0 mw-content">
              {{ 'rogers.verifySms.error.maxOtpAttemptsTried' | translate }}
            </p>
            <p *ngIf='incorrectCode'
              damTranslationWithRouting
              class="text-body mb-0 mw-content">
              {{ 'rogers.verifySms.error.incorrectCode' | translate }}
            </p>

            <p *ngIf='otherError'
              damTranslationWithRouting
              class="text-body mb-0 mw-content">
              {{ 'rogers.verifySms.error.system' | translate }}
            </p>
          </div>
        </div>
      </div>
    </ds-alert>
  </div>

  <div class="mb-16 code-input-container"
  [ngClass]="{'otp-invalid': formSubmitted && !otpFieldIsInvalid}">

  <ds-code-input
    [a11yLabel]="'rogers.verifyEmail.verificationInput' | translate"
    [focusPreviousAfterClearing]="false"
    [length]="6"
    #codeInputRef
    (codeChanged)="onCodeChanged()"
    (codeCompleted)="onCodeCompleted($event)"
    (keydown.enter)="keyDownEnter($event)">
  </ds-code-input>

  <ds-error
  [errorMessage]="'rogers.verifySms.error.enterCode' | translate"
  *ngIf="formSubmitted && !otpFieldIsInvalid"
  role="alert"
  ssTrackError
  [error]="track.otpInputFormError">
</ds-error>



</div>

  <div class="text-body-md mb-32 mt-16 flex-wrap"  [ngClass]="brand == 'media' ? '' : 'd-flex'">
    <p
    class="text-body-md pt-12 mr-4"
    [innerHTML]="'rogers.' + verifyType + '.didntGet' | translate"></p>
    <ng-container
    counter
    [counter]="counterInitialValue"
    (value)="onCounterChange($event)">
    <ng-container *ngIf="resendCodeClick" ssTrackEvent [event]="track.otpResendCode"></ng-container>
    <button
      ds-button
      variant="tertiary"
      (click)="onResendCode()"
      [disabled]="resendDisabled"
      [attr.aria-disabled]="resendDisabled"
      [attr.aria-label]="
        'rogers.' + verifyType + '.accessibility.resendCode' | translate
      "
      [attr.title]="
        'rogers.' + verifyType + '.accessibility.resendCode' | translate
      "
    >
      <span
        class="text-body-lg"
        [innerHTML]="'common.resendCode' | translate"
      >
      </span>
      <ds-icon *ngIf="counter === 0" name="chevron-right"></ds-icon>
      <span
        *ngIf="counter > 0"
        class="ml-8 text-body-lg"
        [innerHTML]="'common.timer' | translate: { counter: counter }">
      </span>
    </button>

    <div *ngIf="showChangeEmailLink">
      <b style="margin-right:10px;">{{ 'common.or' | translate }}</b> 
       <a
       ds-link
       variant="icon"
       class="mb-16 ds-color-link"
       (click)="OnChangeEmailClick()"
       [attr.title]="'common.accessibility.changeEmail' | translate">
       {{ 'common.changeEmail' | translate }}
     </a>
   </div>

  </ng-container>

</div>

<button
ds-button
class="w-100 mb-16"
type="submit"
[attr.title]="'common.accessibility.nextStep' | translate"
#continueBtn>
<span *ngIf="!inProgress">{{'common.continue' | translate}}</span>
  <eas-loader class="eas-loader" *ngIf="inProgress"></eas-loader>
</button>
</div>
</form>

<ng-container *ngIf="showPhoneLink">
  <div _ngcontent-hjv-c91="" class="ds-border-bottom mb-lg-24 mb-sm-0 mt-16 ds-brcolor-concrete ng-star-inserted"></div>
  <div class="d-flex flex-wrap text-body-lg">
    <p class="text-body-lg pt-8 mr-4 mb-0" style="margin-top: 2px;">
      {{ 'rogers.verifySms.footerEmailText' | translate }}
      <button
      ds-button
      variant="tertiary"
      (click)="onSendCodeByDiffentChannel(selectedChannel)"
      [attr.aria-label]="'rogers.verifySms.accessibility.sendToEmail' | translate"
      [attr.title]="'rogers.verifySms.accessibility.sendToPhone' | translate">
      <span class="text-body-lg ">
        {{ 'rogers.verifySms.footerPhoneLink' | translate }}</span>
    </button>
    </p>

  </div>
</ng-container>

<ng-container *ngIf="showEmailLink">
  <div _ngcontent-hjv-c91="" class="ds-border-bottom mb-lg-24 mb-sm-0 mt-16 ds-brcolor-concrete ng-star-inserted"></div>
  <div class="d-flex flex-wrap text-body-lg">
    <p class="text-body-lg pt-8 mr-0 mb-0" style="margin-top: 2px;">
      {{ 'rogers.verifySms.footerPhoneText' | translate }}
      <button
      ds-button
      variant="tertiary"
      (click)="onSendCodeByDiffentChannel(selectedChannel)"
      [attr.aria-label]="'rogers.verifySms.accessibility.sendToEmail' | translate"
      [attr.title]="'rogers.verifySms.accessibility.sendToEmail' | translate">
      <span class="text-body-lg ">
        {{ 'rogers.verifySms.footerEmailLink' | translate }}</span>
    </button>
    </p>
  </div>
</ng-container>


<div *ngIf="showHelp">
<div class="d-flex ds-bgcolor-fog verify-email-footer">
 <div class="verify-email-footer__content d-flex flex-wrap text-body-lg">
  <div  class="text-body-lg pt-8  mr-4" style="margin-top: 2px;">
    {{ 'rogers.verifyEmail.footerText' | translate }}

    <span class="text-body-lg  text-semi" style="margin-top: 10px;margin-left: 10px;">
      <a href="#"
      [attr.aria-label]="'common.aria-live' | translate"
      title="{{ 'common.aria-live' | translate }}"
      onclick="
                  event.preventDefault();
                  window.postMessage({
                      message: 'VA-OPEN',
                      messageData: { buttonId: '999991001006'}
                  }, '/');">{{  'rogers.verifyEmail.reSendEmail.liveChat' | translate}}  ></a></span>
   </div>

<!-- <app-livechat
[altText]="'rogers.verifyEmail.accessibility.liveChat' | translate"
[buttonClass]="'common.forgotLiveChat.cbid' | translate"
[boldChatButtonId]="'accountRecovery'"
[translateCBID]="'common.forgotLiveChat.cbid' | translate"
[isHidden]="true">

<button
  ds-button
  (click)="onLiveChat()"
  variant="tertiary"
  [attr.aria-label]="'rogers.verifyEmail.accessibility.liveChat' | translate"
  [attr.title]="'rogers.verifyEmail.accessibility.liveChat' | translate">
  <span class="text-body-lg  text-semi">
    {{ 'rogers.verifyEmail.footerLink' | translate }}</span> >
</button>
</app-livechat> -->


</div>
</div>
</div>

</div>
