<!-- StartedProject/app_content/authenticate-user_ui.html -->


<div id="authenticated_user_ui">
    <div class="info">
        <div class="info_row">
            <h3>Authenticated User Display UI </h3>
            <label>When a user is succesfully authenticated we can invoke protected methods such as
                <code>logout</code>, <code>invokePolicy</code>, <code>startAuthenticationConfiguration</code> and more.
            </label>
        </div>
        <div class="info_row">
            <button (click)="onLogout()" class="app_submit_button" style="background-color: #FF0000;">Logout</button>
        </div>    
    </div>
    <!--
    <div class="ui_container">
        <button onclick="invokeJourney();" class="app_submit_button full_width">Invoke Money Transfer Journey</button>
        <button onclick="invokeJourneyAddPayee();" class="app_submit_button full_width">Invoke Add Payee Journey</button>
        <button onclick="invokeAuthenticationConfiguration();" class="app_submit_button full_width">Configure Authenticators</button>
        <button onclick="invokeDeviceManagement();" class="app_submit_button full_width">Device Management</button>
    </div>  -->
</div>