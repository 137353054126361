<form  autocomplete="off">
    <ng-container ssTrackEvent [event]="track.selectAuthenticatonFactorsPageview"></ng-container>

           <h2 class="text-title-4 mb-8">
              {{ 'rogers.auth.profile.header' | translate }} 
           </h2>
      
           <div class="text-body-lg mb-10 mt-10">
            <p translate>{{ profileBody }}</p>
          </div>
    
          <div class="text-body-lg mb-10 mt-10">
            <div class="row" *ngIf="showEmailOption">
                <div class="col-md-9">
                    <p><span translate>{{ profileEmail }}</span>: </p>
                     <p><strong> {{ emailDisabled === true ? ( none | translate ) : email }}</strong></p>
                </div>

                <div class="col-md-3">
                    <ds-switch
                    (change)="onEmailToggle($event)"
                    name="custom-name"
                    value="data-manager"
                    [checked]="emailChecked"
                    [disabled]="emailDisabled"
                    title="{{ emailText | translate }}">
                    <span ds-switch-on>{{ 'rogers.auth.on' | translate }}</span>
                    <span ds-switch-off>{{ 'rogers.auth.off' | translate }}</span>
                    </ds-switch>
                </div>
            </div>

            <div class="row mt-12">
                <div class="col-md-9">
                    <p><span translate>{{ profileNumber }}</span>: </p>
                    <p><strong> {{ numberdisabled === true ?  ( none | translate ) : number}}</strong></p>
                </div>

                <div class="col-md-3">
                    <ds-switch
                    (change)="onNumberToggle($event)"
                    name="custom-name"
                    value="data-manager"
                    [checked]="numberChecked"
                    [disabled]="numberdisabled"
                    title="{{ numberText | translate }}">
                    <span ds-switch-on>{{ 'rogers.auth.on' | translate }}</span>
                    <span ds-switch-off>{{ 'rogers.auth.off' | translate }}</span>
                    </ds-switch>
                </div>
            </div>

         </div>
         <ng-container *ngIf="onSaveMfaChanges" ssTrackEvent [event]="track.modalUpdateMFASuccess"></ng-container>

           <button
           type="submit"
           ds-button
           class="w-100 mt-12"
           (click)="onSubmit()"
           [disabled] = "diableSaveChanges"
           #submitBtn
           >
           <span>{{ 'rogers.auth.profile.save' | translate }}</span>
           </button>
</form>


