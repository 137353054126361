import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LiveChatService } from './livechat.service';
import get from 'lodash/get';

@Component({
  selector: 'app-livechat',
  // templateUrl: './livechat.component.html',
  styleUrls: ['./livechat.component.scss'],
  template: `
  <ng-content></ng-content>
  <div [attr.id]="boldChatButtonId"  [attr.data-cbid]="translateCBID" [ngClass]="{ 'd-none': isHidden }"></div>
`
})
export class LivechatComponent implements AfterViewInit, OnChanges, OnInit  {

  @Input() 333: string | number;
  @Input() boldChatButtonId: string;
  @Input() buttonClass: string;
  @Input() errorDesc: string;
  @Input() altText: string;
  @Input() isHidden: boolean;
  @Input() translateCBID: string | number;

  private isFirstTime = true;

  constructor(private readonly liveChatService: LiveChatService) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isFirstTime) {
      return;
    }
    const translateCBID = get(changes, 'translateCBID.currentValue', this.translateCBID);
    const boldChatButtonId = get(changes, 'boldChatButtonId.currentValue', this.boldChatButtonId);
    const btnClass = get(changes, 'buttonClass.currentValue', this.buttonClass);

    setTimeout(() => {
      this.liveChatService.staticLiveChatButton(
        translateCBID,
        boldChatButtonId,
        btnClass,
        get(changes, 'buttonClass.previousValue', null),
        this.errorDesc,
        this.altText
      );
    });
  }

  ngAfterViewInit(): void {
    this.liveChatService.staticLiveChatButton(
      this.translateCBID,
      this.boldChatButtonId,
      this.buttonClass,
      null,
      this.errorDesc,
      this.altText
    );

    this.isFirstTime = false;
  }
}
