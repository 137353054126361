import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-account-selection',
  templateUrl: './account-selection.component.html',
  styleUrls: ['./account-selection.component.scss']
})
export class AccountSelectionComponent implements OnInit {

  showDropDown = false;
  alive = true;
  selectedAddressHhid: string;
  brand: string;

  header = 'rogers.accountSelection.header';
  body = 'rogers.accountSelection.body';

  subscriptionInfoModel: SubscriptionInfoModel[] = [];
  addressTypeModel: AddressTypeModel;

  accountSelectionForm: FormGroup;

  onAccountSubmit: (payload: object) => void;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly cookieService: CookieService) { }

  ngOnInit(): void {
    this.accountSelectionForm = this.formBuilder.group({
      accountNumber: [''],
    });

    this.setTranslatePropertiesByBrand();
  }


  dropdownSelect(e, accounts) {
    this.showDropDown = false;
  }

  toggleDropdownMenu() {
      this.showDropDown = !this.showDropDown;
  }

  submitAccountSelection(){
    const hhid =  this.accountSelectionForm.controls['accountNumber'].value;
    this.onAccountSubmit({
      accountId: hhid
    });
  }

  setTranslatePropertiesByBrand(){
  
    this.brand  = this.cookieService.get('igniteBrand');

    if (this.brand === 'rshm'){
      this.header = 'rshm.accountSelection.header';
      this.body = 'rshm.accountSelection.body';
    }
    else  if (this.brand === 'xfi'){
      this.header = 'xfi.accountSelection.header';
      this.body = 'xfi.accountSelection.body';
    }
    else  if (this.brand === 'ignitetv'){
      this.header = 'igniteTv.accountSelection.header';
      this.body = 'igniteTv.accountSelection.body';
    }
  }

}

export interface SubscriptionInfoModel {
  cbpid: string;
  accountNumber: string;
  hhid: string;
  serviceAddressId: string;
  addressUniqueId: string;
  additionalAddressInfo: string;
  addressType: AddressTypeModel;
  updatedDate: string;
  subscriptionType: string;

}

export interface AddressTypeModel {
  unitNumber: string| number;
  streetNumber: string| number;
  streetName: string;
  streetNameSuffix: string;
  streetType: string;
  compassDirection: string;
  unitType: string;
  unitDesignator: string;
  postalCode: string;
  city: string;
  province: string;
  country: string;
}
