import { ComponentFactory, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { UsernameFormNoRecaptchaComponent } from './username-form-no-recaptcha.component';


class UsernameNoRecaptchaFormSession implements com.ts.mobile.sdk.UIFormSession{
  clientContext: object;
  actionContext: object;
  formId: string;
  payload: object;

  submitHandler: any;
  submitBlock: (payload: object) => void;
  // currentHandler: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;
  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;
  usernameFormNoRecaptchaComponent: ComponentRef<UsernameFormNoRecaptchaComponent>;

  constructor(formId: string, payload: object) {
      console.log('Inside UsernameNoRecaptchaFormTs constructor');
      this.formId = formId;
      this.payload = payload;
  }
  startSession(clientContext: object | null, actionContext: com.ts.mobile.sdk.PolicyAction | null): void {
    console.log('UsernameNoRecaptchaFormTs Form session started: ' + this.formId);
    this.clientContext = clientContext;
    this.actionContext = actionContext;
    this.resolver = (clientContext as any).resolver;
    this.viewContainerRef = (clientContext as any).viewContainerRef;

  }
  endSession(): void {
    console.log('UsernameNoRecaptchaFormTs Form session ended: ' + this.formId);
    // console.log('User locked');
    // setTimeout(() => this.usernameFormSessionCompRef.instance.setErrorCode(), 100)
  }
  promiseFormInput(): Promise<com.ts.mobile.sdk.FormInput> {
    console.log('UsernameNoRecaptchaFormTs Form session Input: ' + this.formId);
    const self = this;
    self.viewContainerRef.clear();

    const factory: ComponentFactory<UsernameFormNoRecaptchaComponent> = 
                        self.resolver.resolveComponentFactory(UsernameFormNoRecaptchaComponent);
    self.usernameFormNoRecaptchaComponent = self.viewContainerRef.createComponent(factory);
    self.usernameFormNoRecaptchaComponent.instance.onSubmitUserId = self.onSubmitUserData.bind(self);
    self.usernameFormNoRecaptchaComponent.instance.onForgotUserEmailSubmit = self.onForgotUsernameSubmit.bind(self);
    self.usernameFormNoRecaptchaComponent.instance.onRegisterClick = self.onRegisterClick.bind(self);
    self.usernameFormNoRecaptchaComponent.instance.onForgotUsernameAccountClick = self.onForgotUsernameAccountClick.bind(self);
    self.usernameFormNoRecaptchaComponent.instance.onVerifyUserEmailSubmit = self.onRegPendingVerifyEmail.bind(self);

    console.log('UsernameNoRecaptchaFormTs constructor payload:', this.payload);

    const journeyPayload = this.payload as JourneyPayload;
    console.log('payload actions using interface', journeyPayload.actions);

    const action = journeyPayload.actions[0] as ActionsInPayload;
    console.log('action flow using interface', action.flow);

    self.usernameFormNoRecaptchaComponent.instance.flowFromPayload = 'login';

    return new Promise((resolve, reject) => {
      self.submitBlock = (payload: object) => {
        try {
          resolve(com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(Object.assign(payload)));
          console.log('UsernameNoRecaptchaFormTs createFormInputSubmissionRequest SUCCESS payload: ', payload);
          self.submitBlock = null; // assign null to prevent using the same promise more then once
        } catch (e) {
          console.log('UsernameNoRecaptchaFormTs createFormInputSubmissionRequest ERROR payload: ', payload);
          console.error(e);
        }
      };
    });
  }
  onContinue(payload: object): void {
    console.log('UsernameNoRecaptchaFormTs onContinue for ' + this.formId + ' payload:', payload);
  }
  onError(payload: object): void {

    const url = this.clientContext['redirect_uri'];
    const state = this.clientContext['state'];

    console.log('UsernameNoRecaptchaFormTs onError for ' + this.formId + ' payload:', payload);
    const errorPayload = payload as JourneyPayload;

    if (errorPayload.code === 'BL02'){
      let appendChar = '?';
      if (url.includes('?'))
      {
         appendChar = '&';
      }
      window.location.href = url + appendChar + 'error=' + errorPayload.code + '&error_description=suspended' 
      + '&lang=' + this.clientContext['cookieService'].get('lang')
      + '&state=' + state;
    }
    else if (errorPayload.code === 'RC01'){
      this.clientContext['cookieService'].set('loginStoreErrorCode', errorPayload.code ? errorPayload.code : null, undefined, undefined, null, true, 'None');
    }
    else {
      this.clientContext['cookieService'].set('loginStoreErrorCode', errorPayload.code ? errorPayload.code : null, undefined, undefined, null, true, 'None');
    }
  }

  /** Non UIFormSession interface Functions */
  private onSubmitUserData(payload: object) {
    this.clientContext['cookieService'].delete('flow');
    this.clientContext['cookieService'].set('userEmail', payload['username'], undefined, undefined, null, true, 'None');
    console.log('UsernameNoRecaptchaFormTs onSubmitUserData for ' + this.formId + ' payload:', payload);
    const data = payload;
    this.submitBlock(data);
  }

  private onForgotUsernameSubmit(payload: object) {
    this.clientContext['cookieService'].set('flow', 'username', undefined, undefined, null, true, 'None');
    console.log('UsernameNoRecaptchaFormTs on forgot submit username for payload:', payload);
    const data = payload;
    this.submitBlock(data);
  }

  private onRegPendingVerifyEmail(payload: object) {
    console.log('UsernameNoRecaptchaFormTs on Reg pending verify user email  for payload:', payload);
    const data = payload;
    this.clientContext['cookieService'].delete('verifyEmail');
    this.submitBlock(data);
  }

  private onRegisterClick(brand: string){

    const url = this.clientContext['redirect_uri'];
    const state = this.clientContext['state'];
    const errorCode  = 'register';
    let appendChar = '?';
    if (url.includes('?'))
    {
       appendChar = '&';
    }
    window.location.href = url + appendChar + 'error=' + errorCode + '&error_description=register'
    + '&lang=' + this.clientContext['cookieService'].get('lang')
    + '&state=' + state;
  }

  private onForgotUsernameAccountClick(){
    const url = this.clientContext['redirect_uri'];
    const state = this.clientContext['state'];
    const errorCode  = 'forgotUsername';
    let appendChar = '?';
    if (url.includes('?'))
    {
       appendChar = '&';
    }
    window.location.href = url + appendChar + 'error=' + errorCode + '&error_description=forgotUsername'
    + '&lang=' + this.clientContext['cookieService'].get('lang')
    + '&state=' + state;
  }
}

interface JourneyPayload {
  actions: ActionsInPayload[];
  stat: string;
  code: string;
  flow: string;
}

interface ActionsInPayload {
  [key: string]: any;
}

export default UsernameNoRecaptchaFormSession;
