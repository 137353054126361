import { ComponentFactory, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { SecurityQuestionComponent } from './security-question.component';

export class SecurityQuestionFormSession {
  clientContext: any;
  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;
  securityQuestionComponentRef: ComponentRef<SecurityQuestionComponent>;

  actionContext: any;
  submitHandler: any;
  mode: any;
  title: string;
  username: string;
  description: any;
  actionEscapeOption: any;


  constructor(title, username){
      console.log('Security Question Form Constructor');
      this.title = title;
      this.username = username;
  }
  // this.submitHandler = null;

  startSession(
    description: com.ts.mobile.sdk.AuthenticatorDescription,
    mode: com.ts.mobile.sdk.AuthenticatorSessionMode,
    actionContext: com.ts.mobile.sdk.PolicyAction | null,
    clientContext: object | null
  ): void {
    console.log('Security Question Form Started new session');
    this.actionContext = actionContext;
    this.clientContext = clientContext;
    this.mode = mode;

    this.description = description;
    this.actionEscapeOption = false;
  }

  endSession(): void {
    console.log('Security Question Form session ended');
  }

  promiseInput(): Promise<com.ts.mobile.sdk.InputOrControlResponse<ResponseType>>{
    const self = this;
    self.resolver = (self.clientContext as any).resolver;
    self.viewContainerRef = (self.clientContext as any).viewContainerRef;
    self.viewContainerRef.clear();

    const factory: ComponentFactory<SecurityQuestionComponent> = self.resolver.resolveComponentFactory(SecurityQuestionComponent);
    self.securityQuestionComponentRef = self.viewContainerRef.createComponent(factory);

    this.securityQuestionComponentRef.instance.securityQuestion = this.actionContext.getUiContext().getString("question");
    self.securityQuestionComponentRef.instance.onSecurityAnwerSubmit = self.onSecurityQuestionSubmit.bind(self);
    self.securityQuestionComponentRef.instance.onCancelSubmit = self.onCancel.bind(self);

    return new Promise((resolve, reject) => {
      self.submitHandler = (response) => {
        resolve(response);
      };
    });
  }

  promiseRecoveryForError(
    error: com.ts.mobile.sdk.AuthenticationError,
    validRecoveries: Array<com.ts.mobile.sdk.AuthenticationErrorRecovery>,
    defaultRecovery: com.ts.mobile.sdk.AuthenticationErrorRecovery
  ): Promise<com.ts.mobile.sdk.AuthenticationErrorRecovery> {
    return new Promise((resolve, reject) => {

      console.log(`Security Question Form PromiseRecoveryForError was called with error: ${error}`);
      if (error){
        const attemptsLeft = error.getData()?.['additional_data']?.['retries_left'];
        if (attemptsLeft > 0){
          setTimeout(() => this.securityQuestionComponentRef.instance.setErrorCode('invalid'), 100);
        }
    }
      resolve(defaultRecovery);
    });
  }

  private onSecurityQuestionSubmit(answer: string) {
    const inputResponse = com.ts.mobile.sdk.PlaceholderInputResponse.createSuccessResponse(answer);
    const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputResponse);
    this.submitHandler(response);
  }

  private onCancel() {
    const url = this.clientContext['redirect_uri'];
    const state = this.clientContext['state'];
    const errorCode  = 'cancel_forgotPassword';
    let appendChar = '?';
    if (url.includes('?'))
    {
       appendChar = '&';
    }
    window.location.href = url + appendChar + 'error=' + errorCode + '&error_description=cancel_forgotPassword' 
    + '&lang=' + this.clientContext['cookieService'].get('lang')
    + '&state=' + state;
  }

}


export default SecurityQuestionFormSession;
