import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';

import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import { IConfiguration } from './configuration.interface';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { WINDOW } from './window.token';
import { clientMapping, SiteNames } from './analytics/analytics-mapping-config';
import { AnalyticsServiceProxy } from './analytics/analytics-service-proxy.service';

const EXTERNAL_SCRIPT_LOAD_TIMEOUT = 3000;

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  private readonly configData: IConfiguration;

  constructor(
    private readonly http: HttpClient,
    private readonly translate: TranslateService,
    private readonly cookieService: CookieService,
    @Inject(WINDOW) private readonly window: Window,
    @Inject('environment') environment
  ) {
    this.configData = {
      brand: environment.brand,
      appVersion: environment.appVersion,
      loginApiUrl: null,
      analyticsUrl: environment.analyticsUrl,
      analyticsUrlR4B: environment.analyticsUrlR4B,
      analyticsUrlFido: environment.analyticsUrlFido,
      analyticsUrlChatr: environment.analyticsUrlChatr,
      chatUrl: environment.chatUrl,
      msBaseUrl: null,
      recaptchaSiteKey: environment.recaptchaSiteKey,
      recaptchaV3SiteKey: environment.recaptchaV3SiteKey,
      useRecaptchaV3: true,
      dataDogId: environment.dataDogId,
      dataDogToken: environment.dataDogToken,
      dynatraceEnv: environment.dynatraceEnv,
      dataDogService: environment.dataDogService,
      rememberMeCookieName: environment.rememberMeCookieName,
      rememberMeFlag: environment.rememberMeFlag,
      rememberMeCookieExpiry: environment.rememberMeCookieExpiry,
      passwordValidationPattern: environment.passwordValidationPattern,
      passwordValidationPatternWithoutLength:
      environment.passwordValidationPatternWithoutLength,
      emailValidationPattern: environment.emailValidationPattern,
      loginCookieName: environment.loginCookieName,
      remindMeCookieName: environment.remindMeCookieName,
      remindChangeUsernameCookieName: environment.remindChangeUsernameCookieName,
      cookieExpire: environment.cookieExpire,
      remindMeCookieExpiry: environment.remindMeCookieExpiry,
      remindMeSkipCookieExpiry: environment.remindMeSkipCookieExpiry,
      validateEmailLinkTimeout: environment.validateEmailLinkTimeout,
      consumerContactUs: environment.consumerContactUs,
      patUserTempKeyName: environment.patUserTempKeyName,
      defaultTargetAfterSignIn: environment.defaultTargetAfterSignIn,
      registrationPageUrl: environment.registrationPageUrl,
      registrationPrepaidUrl: environment.registrationPrepaidUrl,
      recoverySmsAddUrl: environment.recoverySmsAddUrl,
      businessAccountUrl: environment.businessAccountUrl,
      registerBusinessAccountUrl: environment.registerBusinessAccountUrl,
      liveChatServiceConfig: {
        accountId: environment.liveChatServiceConfig.accountId,
        websiteId: environment.liveChatServiceConfig.websiteId
      },
      publicUrlPrefix: environment.publicUrlPrefix,
      userStorageKey: environment.userStorageKey,
      xSessionTokenName: environment.xSessionTokenName,
      guidName: environment.guidName,
      profileActivationDateName: environment.profileActivationDateName,
      x_session_token: environment.x_session_token,
      homeURL: environment.homeURL,
      fidohomeURL: environment.fidohomeURL,
      yahoohomeURL: environment.yahoohomeURL,
      r4bhomeURL: environment.r4bhomeURL,
      chatrhomeURL: environment.chatrhomeURL,
      sessionTimeout: environment.sessionTimeout
    };
  }

  loadConfigurationData(url: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<IConfiguration>(url)
        .pipe(
          catchError(_ => {
            return of({});
          })
        )
        .subscribe((responseData: any) => {
          console.log(
            'the ciamLoginUrl data from configuration is: ',
            get(responseData, 'login.ciamLoginUrl')
          );
          if (get(responseData, 'login.ciamLoginUrl')) {
            this.configData.loginApiUrl = get(responseData, 'login.ciamLoginUrl');
          }
          if (get(responseData, 'login.recaptchaV3SiteKey')) {
            this.configData.recaptchaV3SiteKey = get(
              responseData,
              'login.recaptchaV3SiteKey'
            );
          }
          if (get(responseData, 'login.recaptchaSiteKey')) {
            console.log(
              'the recaptchaSiteKey data from configuration is: ',
              get(responseData, 'login.recaptchaSiteKey')
            );
            this.configData.recaptchaSiteKey = get(
              responseData,
              'login.recaptchaSiteKey'
            );
          }
          if (get(responseData, 'login.chatUrl')) {
            this.configData.chatUrl = get(responseData, 'login.chatUrl');
            console.log('chat url ', this.configData.chatUrl);
            this.loadchatUrl();
            // this.configData.dynatraceEnv = get(responseData, 'dynatraceEnv');
            // this.setApplicationDataDog();
          }
          // Load Analytics
          this.loadAnalytics();

          this.configData.msBaseUrl = get(
            responseData,
            'login.msBaseUrl',
            null
          );
          resolve(true);
        });
    });
  }

  // setApplicationDataDog() {
  //   datadogRum.init({
  //     applicationId: this.config.dataDogId,
  //     clientToken: this.config.dataDogToken,
  //     site: 'datadoghq.com',
  //     service: this.config.dataDogService,
  //     env: this.config.dynatraceEnv,
  //     sampleRate: 5,
  //     version: this.config.appVersion,
  //     trackInteractions: true,
  //     allowedTracingOrigins: [/^https:\/\/.*mservices.(rogers.com|fido.ca)/]
  //   });
  // }

  get config(): IConfiguration {
    return cloneDeep<IConfiguration>(this.configData);
  }
  get loginRecaptchaKey(): string {
    return this.configData.recaptchaSiteKey;
  }

  get loginRecaptchaV3Key(): string {
    return this.configData.recaptchaV3SiteKey;
  }

  get useRecaptchaV3(): boolean {
    return this.configData.useRecaptchaV3;
  }

  get msBaseUrl(): string {
    return this.configData.msBaseUrl;
  }

  loadAnalytics() {
    // One Launch Property
    if (typeof window === 'undefined') {
      return;
    }
    const windowObj = (window as any);
    if (!windowObj._analyticsHelpers) {
      windowObj._analyticsHelpers = {};
    }
    if (!windowObj._analyticsHelpers.queue) {
      windowObj._analyticsHelpers.queue = [];
    }
    if (!windowObj.AnalyticsDataLayer) {
      windowObj.AnalyticsDataLayer = {};
    }
    windowObj._analyticsHelpers.marketingPixels = 'eas-authentication';

    var analyticsBrand = this.cookieService.get('brand');
    var platform = this.cookieService.get('platform');

    if (analyticsBrand === 'rogers')
    {
      if (platform === 'ios' || platform === 'android')
      {
        analyticsBrand = 'rogersApp'; //override for mobile
      }
    }

    if (analyticsBrand === 'fido')
    {
      if (platform === 'ios' || platform === 'android')
      {
        analyticsBrand = 'fidoApp'; //override for mobile
      }
    }

    const igniteBrand = this.cookieService.get('igniteBrand');
    // we need seperate analytics signin type for the below brands
    if (igniteBrand === 'rshm' || igniteBrand === 'xfi' || igniteBrand === 'ignitetv'){
      analyticsBrand = igniteBrand;
    }
   

    windowObj._analyticsHelpers.site = AnalyticsServiceProxy.getMapping(
      analyticsBrand, SiteNames);
    windowObj._analyticsHelpers.easHierarchyLevel1 = AnalyticsServiceProxy.getMapping(
      analyticsBrand, clientMapping)['hierLevel1'];
    return new Promise<void>(resolve => {
      const script: any = window.document.createElement('script');
      if (windowObj._analyticsHelpers.site == 'R-Biz')
      {
        script.src = this.configData.analyticsUrlR4B;
      }
      else if (windowObj._analyticsHelpers.site == 'F')
      {
        script.src = this.configData.analyticsUrlFido;
      }
      else if (windowObj._analyticsHelpers.site == 'chatr')
      {
        script.src = this.configData.analyticsUrlChatr;
      }     
      else
      {
        script.src = this.configData.analyticsUrl;
      }
      script.async = true;
      script.onload = resolve;
      window.document.body.appendChild(script);
      const done = () => {
        script.onreadystatechange = null;
        script.onload = null;
        clearTimeout(timeout);
        resolve();
      };
      // if the script doesn't load for a while, we'll just continue without it
      const timeout = setTimeout(() => {
        window.console.log('Could not load external script', script.src);
        done();
      }, EXTERNAL_SCRIPT_LOAD_TIMEOUT);

      if (!script.readyState) {
        script.onload = done;
        return;
      }
      // IE
      script.onreadystatechange = () => {
        if (
          script.readyState === 'loaded' ||
          script.readyState === 'complete'
        ) {
          done();
        }
      };
    });
  }

  loadchatUrl() {
    if (typeof window === 'undefined') {
      return;
    }
    const windowObj = (window as any);
    return new Promise<void>(resolve => {
      const script: any = window.document.createElement('script');
      script.src = this.configData.chatUrl;
      script.async = true;
      script.onload = resolve;
      window.document.body.appendChild(script);
      const done = () => {
        script.onreadystatechange = null;
        script.onload = null;
        clearTimeout(timeout);
        resolve();
      };
      // if the script doesn't load for a while, we'll just continue without it
      const timeout = setTimeout(() => {
        window.console.log('Could not load external script', script.src);
        done();
      }, EXTERNAL_SCRIPT_LOAD_TIMEOUT);

      if (!script.readyState) {
        script.onload = done;
        return;
      }
      // IE
      script.onreadystatechange = () => {
        if (
          script.readyState === 'loaded' ||
          script.readyState === 'complete'
        ) {
          done();
        }
      };
    });
  }
}
