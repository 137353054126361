import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from './configuration.service';
import { SESSION_STORAGE } from './session-storage.token';
import { GlobalStateService, User } from './global-state.service';
enum EASY_LOGIN_COMMUNICATION_CHANNEL_METHOD {
  redirect = 0
}

interface IHostCommunicationChannel {
  easyLoginMethod: string;
  value: any;
}

interface IShiledCommunicationChannel {
  messageSource: string;
  damShieldToken: string;
}
@Injectable({
  providedIn: 'root'
})
export class OutboundNavigationService {
  private readonly windowObj: any = {};
  constructor(
    private readonly cookieService: CookieService,
    private readonly translateService: TranslateService,
    private readonly globalStateService: GlobalStateService,
    private readonly configurationService: ConfigurationService,
    @Inject(SESSION_STORAGE) private readonly sessionStorage: Storage
  ) {
    this.windowObj = OutboundNavigationService.isInsideIframe()
      ? window.parent
      : window;
  }

  static isInsideIframe() {
    return !!window.frameElement;
  }

  static getRedirectMethod(
    methodEnum: EASY_LOGIN_COMMUNICATION_CHANNEL_METHOD
  ) {
    return EASY_LOGIN_COMMUNICATION_CHANNEL_METHOD[methodEnum];
  }

  /**
   * Close Modal from parent of the iframe
   */
  closeModalAndNavigate(_target: string, isSignedIn: boolean) {
    this.cookieService.set(
      'language',
      this.translateService.currentLang,
      undefined,
      '/',
      null,
      true,
      'None'
    );
    if (this.globalStateService.isFido()) {
      // have to separate logic as fix for DSO-8412 is only applicable for rogers/ it should be implemented for
      this.closeModalAndNavigateFido(_target, isSignedIn);
    } else {
      let target = _target;
      const key = 'ute.rci.deep.link.url';
      // the key is not set in this spa, is being set at another SPA? --Naresh
      const deeplink = this.sessionStorage.getItem(key);
      this.sessionStorage.removeItem(key);
      // Check if user is Signed in, then handle the deep link, otherwise ignore & delete it and proceed with flow
      if (isSignedIn) {
        this.storeSecondarySigninPayload();
        /**
         * Check deepJSON sessionStorage
         */
        if (deeplink) {
          target = deeplink;
          this.cookieService.set('deepLinkProcessed', 'true',undefined, undefined, null, true, 'None');
          if (deeplink.indexOf('##post##') > -1) {
            if (OutboundNavigationService.isInsideIframe()) {
              this.postMessageToHost({
                easyLoginMethod: OutboundNavigationService.getRedirectMethod(
                  EASY_LOGIN_COMMUNICATION_CHANNEL_METHOD.redirect
                ),
                value: target
              });
              return;
            }
            target = deeplink.replace('##post##', '');
          }
        }
      }
      this.windowObj.open(target, '_self');

      if (OutboundNavigationService.isInsideIframe()) {
        this.closeModal();
      }
    }
  }

  private closeModalAndNavigateFido = (
    _target: string,
    isSignedIn: boolean
  ) => {
    let target = _target;
    const deeplink = this.getDeepLinkUrl();
    // Check if user is Signed in, then handle the deep link, otherwise ignore & delete it and proceed with flow
    if (isSignedIn) {
      this.storeSecondarySigninPayload();
      if (deeplink) {
        if (deeplink.indexOf('wireless') === -1) {
          target = deeplink;
        }
        this.cookieService.set('deepLinkProcessed', 'true',undefined, undefined, null, true, 'None');
      }
    }
    if (OutboundNavigationService.isInsideIframe()) {
      this.postMessageToHost({
        easyLoginMethod: OutboundNavigationService.getRedirectMethod(0),
        value: target
      });
      this.closeModal();
      return;
    }

    window.open(target, '_self');
  }

  closeShieldModalAndNagivate(damShieldToken: string = null) {
    this.postMessageToHostForShield({
      messageSource: 'DAM',
      damShieldToken: !!damShieldToken ? damShieldToken : null
    });
    const closeBtn = this.windowObj.document.getElementById('closeModalButton');
    // if app is used without an iframe
    if (closeBtn) {
      closeBtn.click();
    }
  }

  closeModal() {
    if (OutboundNavigationService.isInsideIframe()) {
      const closeBtn =
        this.windowObj.document.getElementById('closeModalButton');
      // if app is used without an iframe and loginContext is available
      closeBtn.click();
    } else {
      setTimeout(_ => {
        window.location.href = '/';
      });
    }
  }

  // TODO: storeSecondarySigninPayload and isDeeplink need to be move to a proper location
  private storeSecondarySigninPayload() {
    const siteName = this.globalStateService.isFido() ? 'MyFido' : 'MyRogers';
    this.sessionStorage.setItem(
      'secondarySigninPayload',
      JSON.stringify({
        signinTypeSuccess:
          siteName +
          (this.globalStateService.isDeeplink() ? '|deeplink' : '|regular'),
        loginSucceeded: true
      })
    );
  }

  private getDeepLinkUrl() {
    let deeplink = '';
    try {
      // TODO: Some pages are setting a cookie named 'cookieName' instead of 'deepJSON' for deep-linking.
      //  To be on the safe side on this release, we still support "cookieName" cookie as well but in the
      //  next release we should remove it and ask other teams to use the right cookie.
      deeplink =
        this.cookieService.get('cookieName') ||
        this.cookieService.get('deepJSON');
      this.cookieService.delete('deepJSON', '/');
      this.cookieService.delete('cookieName', '/');
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log('el => deepJSON cookie not found on: path=/');
    }

    return deeplink;
  }

  private postMessageToHost(payload: IHostCommunicationChannel) {
    this.windowObj.postMessage(payload, window.parent.location.href);
    // Please prevent leaking of data, only use communication channel with host
    // by using targetOrigin as window.parent.location.href
  }

  private postMessageToHostForShield(payload: IShiledCommunicationChannel) {
    const event = new CustomEvent('dam-shield-success-event', {
      detail: payload
    });
    this.windowObj.document.dispatchEvent(event);
  }

  updateLoginState(user: User) {
    this.cookieService.set(this.configurationService.config.userStorageKey,
      JSON.stringify(user),undefined, undefined, null, true, 'None');
  }
}
