import { ComponentFactoryResolver, ViewContainerRef, ComponentRef, ComponentFactory } from '@angular/core';
import CustomUIHandler from './customUIHandler';
import { HttpClient } from '@angular/common/http';
import { AuthenticatedUser } from '../otpSession+UI/authenticated-user.component';
import { TranslateService } from '@ngx-translate/core';
import { Brand, CDKCoreService } from '@rogers/cdk/core';
import get from 'lodash/get';
import { CookieService } from 'ngx-cookie-service';



declare var pid: string;
declare var sid: string;
declare var aid: string;
declare var token: string;
declare var hostname: string;
declare var authRequestContextToken: string;
declare var sessionContextToken: string;
declare var onSuccessResult: (username: string, authToken: string) => void;
declare var onErrorResult: (errorMessage: string) => void;
declare var ui_locales: string;
declare var brand: Brand;
declare var csmID: any;
declare var prompt: string;
declare var platform: string;



export interface UIContext {
  viewContainerRef: ViewContainerRef;
  resolver: ComponentFactoryResolver;
}

export class TransmitService {
  clientContext: object;

  constructor(
    sdk: com.ts.mobile.sdk.TransmitSDKXm,
    uiHandler: com.ts.mobile.sdk.UIHandler,
    uiContext: UIContext,
    private http: HttpClient,
    private readonly translate: TranslateService,
    private coreService: CDKCoreService,
    private readonly cookieService: CookieService
  ) {
      this.uiContext = uiContext;
      this.setupTransmitSDK(sdk, uiHandler);
  }

  transmitSDK: com.ts.mobile.sdk.TransmitSDKXm;
  uiContext: UIContext;
  useCustomUIHandler =  true;
  authenticatedUserRef: ComponentRef<AuthenticatedUser>;

  // global variables set in index.html (Transmit additions)
  pid: string = pid;
  sid: string = sid;
  aid: string = aid;
  token: string = token;
  hostname: string = hostname;
  authRequestContextToken: string = authRequestContextToken;
  sessionContextToken: string = sessionContextToken;
  ui_locales: string = ui_locales;
  platform: string = platform;
  brand = brand;
  csmID: any = csmID;
  prompt: string = prompt;
  showSPA: boolean = true;
  marketingCloudID: string;
  mcmid: string;
  xDomSessionGuid: string;

  onSuccessResult: (username: string, authToken: string) => void = onSuccessResult;
  onErrorResult: (errorMessage: string) => void = onErrorResult;

  private CONSTS = {
    SESSION_STORAGE_KEY: {
      TRANSMIT_TOKEN: 'transmit_token',
      CURRENT_USER_ID: 'transmit_current_user_id'
    }
  };

  public authenticate(authToken: any): Promise<com.ts.mobile.sdk.AuthenticationResult> {
    return new Promise<com.ts.mobile.sdk.AuthenticationResult>((resolve, reject) => {
      const self = this;
      // const loginJourneyName = 'validateGuidZoran'; // in case different brands have different journeys
      const policyId = self.pid; // validateGuidZoran
      const idpParams =
          {
            session_id: self.sid,
            token: authToken,
            '$idp_inline_completion': true,
            authRequestContext: self.authRequestContextToken !== '%authRequestContextToken%' ? self.authRequestContextToken : '',
            csmID: self.csmID,
            mktCloudID: self.mcmid,
            xDomSessionGuid: self.xDomSessionGuid,

            // sessionContext: self.sessionContextToken !== '%sessionContextToken%' ? self.sessionContextToken : ''
          };

      this.clientContext = self.getClientContext();
      this.clientContext['cookieService'] = this.cookieService;

      self.transmitSDK.invokeAnonymousPolicy(
        policyId,
        idpParams,
        this.clientContext)
        .then((successfulAuth) => {
          const request = 'request';

          if (successfulAuth.getData() && successfulAuth.getData()[request] && successfulAuth.getData()[request].url)
          {
            this.showSPA = false;
            console.log('successful auth. redirect to ', successfulAuth.getData()[request].url);
            window.location = successfulAuth.getData()[request].url;
            // We need to keep this logout, because it is internal mechanism within transmit to clear 
            // native transmit session 
            self.transmitSDK.logout()
            .then(() => {
              // self.onSuccessResult(successfulAuth.getInternalData().json_data.username, successfulAuth.getToken());
              console.log('successfully logged out.');
            })
            .catch((logoutError) => {
              self.onErrorResult((logoutError.getMessage && logoutError.getMessage()) || logoutError.toString() );
            });

          }
          else{
            self.transmitSDK.logout();
          }
        })
        .catch((err) => {
          console.error(`Transmit service err : ${err}`);
          console.error(JSON.stringify(err,null,2));
          const error =  get(err.getData(), 'failure_data.reason.data.error');
          const errorDescription =  get(err.getData(), 'failure_data.reason.data.error_description');

          const reason_type = get(err.getData(), 'failure_data.reason.type');

          console.log('error : ', error);
          console.log('errorDescription : ', errorDescription);
          console.log('reason_type : ', reason_type);

          this.showSPA = false;
          const url = this.cookieService.get('redirect_uri');
          let appendChar = '?';
          if (url.includes('?'))
          {
             appendChar = '&';
          }

          if (errorDescription === 'invalid id_token_hint' || errorDescription === 'SSO not Found')
          {
            console.log('redirect_uri : ', url);
            const state = this.cookieService.get('state');
            console.log('state : ', state);
            let redirect_url = url + appendChar + 'error=' + error
            + '&error_description=' +  errorDescription
            + '&lang=' + this.cookieService.get('lang')
            + '&state=' + state;
            console.warn('redirect_url : ', redirect_url);
            window.location.href = redirect_url;
          }
          else if (reason_type == 'locked')
          {
            const regFlow = this.cookieService.get('regFlow');
            console.log('User Locked - regFlow: ', regFlow);

            const profilePage = this.cookieService.get('profilePage');

            let error_code = '';
            if (profilePage === 'recoveryNumber' || profilePage === 'changeUsername'){
              error_code = 'UE07';
            }
            else if (regFlow === 'SELF_REG' || regFlow === 'create'){
              error_code = 'UE08';
            }
            else {
              error_code = 'UE06';
            }

            const state = this.cookieService.get('state');
            let redirect_url = url + appendChar + 'error=' + error_code
                + '&error_description=' +  'Account Locked. Incorrect PIN limit exceeded'
                + '&lang=' + this.cookieService.get('lang')
                + '&state=' + state;
            console.warn('redirect_url : ', redirect_url);
            window.location.href = redirect_url;

          }
          else if (error == null)
          {
            const state = this.cookieService.get('state');
            let redirect_url = url + appendChar + 'error=' + 'SE01'
            + '&error_description=' +  'Sorry! Something went wrong. Please try again or check back later.'
            + '&lang=' + this.cookieService.get('lang')
            + '&state=' + state;
            console.warn('redirect_url : ', redirect_url);

            if (this.clientContext){
              const userId = this.clientContext['UserId'];
              if (userId !== null || userId !== undefined){
                this.transmitSDK.clearDataForUser(userId);
              }
            }
            window.location.href = redirect_url;

          }
          else
          {
            const state = this.cookieService.get('state');
            let redirect_url = url + appendChar + 'error=' + error
            + '&error_description=' +  errorDescription
            + '&lang=' + this.cookieService.get('lang')
            + '&state=' + state;
            console.warn('redirect_url : ', redirect_url);
            window.location.href = redirect_url;

          }
        });
    });
  }

  /** Non UIFormSession interface Functions */
  private transmitJourneyEnded() {
    console.log(`Inside transmitJourneyEnded`);
    this.uiContext.viewContainerRef.clear();
    const factory: ComponentFactory<AuthenticatedUser> = this.uiContext.resolver.resolveComponentFactory(AuthenticatedUser);
    this.authenticatedUserRef = this.uiContext.viewContainerRef.createComponent(factory);
    this.authenticatedUserRef.instance.onLogout = this.logout;
  }


  public logout(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        this.transmitSDK.logout()
            .then((success: boolean) => {
                this.updateSessionToken(null);
                this.updateSessionInfo(this.CONSTS.SESSION_STORAGE_KEY.CURRENT_USER_ID, null);
                resolve(true);
            });
    });
  }

  public getCurrentUserId(): string | null {
    return this.getSessionInfo(this.CONSTS.SESSION_STORAGE_KEY.CURRENT_USER_ID);
  }

  public isLoggedIn(): boolean {
    return this.getSessionToken() !== null;
  }

  public invokeJourney(policy: string, additionalParams: object): Promise<com.ts.mobile.sdk.AuthenticationResult> {
    return new Promise<com.ts.mobile.sdk.AuthenticationResult>((resolve, reject) => {
      this.transmitSDK.invokePolicy(policy, additionalParams, this.clientContext)
        .then((results: com.ts.mobile.sdk.AuthenticationResult) => {
            resolve(results);
        })
        .catch((error: com.ts.mobile.sdk.AuthenticationError) => {
            reject(error);
        });
    });
  }

  public clearUIHandlerContainer = (): void => {
    this.uiContext.viewContainerRef.clear();
  }

  private getClientContext = (): object | null => {
    return {
      uiContainer: this.uiContext.viewContainerRef.element.nativeElement, // *** used by the default ui handler ***
      viewContainerRef: this.uiContext.viewContainerRef,
      resolver: this.uiContext.resolver
      // uiContainer: transmitContainer.element.nativeElement
    };
  }

  private extractMCMID(cookieValue) {

    try {
        const arr = cookieValue.split("|MCMID|");
        let text = arr[1];    
        text = text.split('|')[0];
        return text;
      } 
    catch (error) 
      {
        console.error('error extracting MCMID');
        console.error(error.name);
        console.error(error.message);
        console.error(error.stack);
        return '';
      }
    
  }

  private getAnalyticsCookie(cookieName) {
    let cookie = '';
    try {      
      cookie =
        this.cookieService.get(decodeURIComponent(cookieName));
        console.log(cookieName, ' cookie value =>', cookie);

    } catch (error) {
      console.error('Cookie not found => ', cookieName);
    }

    return cookie;
  }

  private setupTransmitSDK(sdk: com.ts.mobile.sdk.TransmitSDKXm, uiHandler: com.ts.mobile.sdk.UIHandler) {
    const defaultUIHandler = new (uiHandler as any).XmUIHandler(); // cast to 'any' to use XmSdk
    this.transmitSDK = (sdk as any).XmSdk(); // cast to 'any' to use XmSdk

    // save the brand value in client before overriding
    this.cookieService.set('igniteBrand', this.brand, undefined, undefined, null, true, 'None');

    let client = this.brand === 'cbu' ? 'rogers' : this.brand;
    client = client === 'cpp' ? 'chatr' : client;
    client = client === 'rshm' ? 'rogers' : client;
    client = client === 'xfi' ? 'rogers' : client;
    client = client === 'ignitetv' ? 'rogers' : client;

    this.cookieService.set('client', client, undefined, undefined, null, true, 'None');

    this.brand = (this.brand === 'cbu') ? 'rogers' : this.brand;
    this.brand = (this.brand === 'cpp') ? 'chatr' : this.brand;

    this.brand = (this.brand === 'media') ? 'rogers' : this.brand;

    this.brand = (this.brand === 'yahoo') ? 'rogers' : this.brand;
    console.log('initial brand value - ' + this.brand);

    const igniteBrand = this.cookieService.get('igniteBrand');

    if (igniteBrand === 'rshm' || igniteBrand === 'xfi' || igniteBrand === 'ignitetv'){
      this.brand = 'rogers';
    }

    this.cookieService.set('lang', this.ui_locales, undefined, undefined, null, true, 'None');
    this.cookieService.set('brand', this.brand, undefined, undefined, null, true, 'None');

    this.cookieService.set('csmID', this.csmID, undefined, undefined, null, true, 'None');
    this.cookieService.set('platform', this.platform, undefined, undefined, null, true, 'None');

    this.marketingCloudID = this.getAnalyticsCookie('AMCV_D7FD34FA53D63B860A490D44%40AdobeOrg');
    if (this.marketingCloudID)
    {
      this.mcmid = this.extractMCMID(this.marketingCloudID);
    }
    else
    {
      this.mcmid = '';
    }

    this.xDomSessionGuid = this.getAnalyticsCookie('X_DOM_session_guid');
    if (this.prompt === 'none')
    {
      this.showSPA = false;
    }
    else
    {
      this.showSPA = true;
    }
    this.translate.use(this.cookieService.get('lang'));
    this.brand = this.cookieService.get('brand');
console.log('brand value - ' + this.cookieService.get('brand'));

    // media and yahoo both use rogers cdk, so override brand valua
    if (this.brand === 'yahoo')
    {
      this.brand = 'rogers';
    }


    this.coreService.set({ brand: this.brand });

    if (this.useCustomUIHandler) {
      this.transmitSDK.setUiHandler(new CustomUIHandler());
    } else {
      this.transmitSDK.setUiHandler(defaultUIHandler);
    }

    this.transmitSDK.setConnectionSettings(this.getConnectionSettings());

    // Uncomment for debugging
    // -----------------------
    // this.transmitSDK.setLogLevel(com.ts.mobile.sdk.LogLevel.Debug);

    // Device fingerprinting configuration

    this.transmitSDK.setEnabledCollectorsForAnonymousJourneys(
      [
          com.ts.mobile.sdk.CollectorType.Capabilities,
          com.ts.mobile.sdk.CollectorType.DeviceDetails,
          com.ts.mobile.sdk.CollectorType.DeviceFingerprint,
          com.ts.mobile.sdk.CollectorType.ExternalSDKDetails
      ]);

    // this.transmitSDK.setLogLevel(com.ts.mobile.sdk.LogLevel.Debug);

    this.transmitSDK.initialize()
      .then((success: boolean) => {
        console.log('SDK Initialized successfully');

        setTimeout(this.checkUserLocked, 1000);

      }).catch((error: any) => {
        console.error(`Initialization error occurred: ${error.getMessage()}`);
      });
  }

    //  check if we have locked user dialog box in dom 
    checkUserLocked() {
      this.showSPA = false;
      let transmitContainerHTML = document.getElementById("transmitContainer").innerHTML;
      let str = 'User Locked';
      let lockedFound = transmitContainerHTML.includes(str);

      if (transmitContainerHTML.indexOf(str) !== -1){ 
        console.error('User is locked by Transmit');
        let continueBtn: HTMLElement= document.querySelectorAll("button.xmui-button")[0] as HTMLElement;
        console.error('continueBtn ', continueBtn);
        continueBtn.click();
      }
      else {
        console.error('User is NOT locked by Transmit');
        this.showSPA = true;

      }
  }

  private getConnectionSettings(): com.ts.mobile.sdk.SDKConnectionSettings {
    // based on aid being set
   // const serverUrl = this.aid !== '%aid%' ? '' : 'https://rogers-dev.transmit.security';
    const serverUrl = this.hostname;
    const appId = this.aid;
    const apiTokenId = null;
    const apiToken = null;
    const realm = '';
    const settings = com.ts.mobile.sdk.SDKConnectionSettings.create(serverUrl, appId, apiTokenId, apiToken);

    this.cookieService.set('oidc_sid', this.sid, undefined, undefined, null, true, 'None');
    if (realm) {
      settings.setRealm(realm);
    }

    return settings;
  }

  /** Session Storage */

  private updateSessionToken(tokenString: string | null) {
    if (!tokenString) {
      sessionStorage.removeItem(this.CONSTS.SESSION_STORAGE_KEY.TRANSMIT_TOKEN);
      return;
    }
    sessionStorage.setItem(this.CONSTS.SESSION_STORAGE_KEY.TRANSMIT_TOKEN, tokenString);
  }

  private getSessionToken() {
    return sessionStorage.getItem(this.CONSTS.SESSION_STORAGE_KEY.TRANSMIT_TOKEN);
  }

  private updateSessionInfo(key: string, value: string | null) {
    sessionStorage.setItem(key, value);
  }

  private getSessionInfo(key: string): string | null {
    return sessionStorage.getItem(key);
  }
}
