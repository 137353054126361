<div>
  <form  (ngSubmit)="onConfirmSubmit()" autocomplete="off">
    <ng-container ssTrackEvent [event]="track.changeUsernameConfirmPageview"></ng-container>
     <h1 class="text-title-4 mb-8">
        {{ 'rogers.changeUsername.confirmUsernameForm.header' | translate }}
     </h1>
     
     <div class="mb-16" *ngIf="error === 'tooManyAttemptsOrExpired'">
      <ds-alert
        variant="error"
        [closeLabel]="'rogers.login.popOverClose' | translate"
        (closeClick)="resetErrorMessage()"
        role="alert"
        ssTrackError
        [error]="track.changeUsernameConfirmErrors">
        <div class="d-flex flex-column flex-md-row">
          <div class="flex-grow-1 d-flex">
            <ds-icon
              class="mr-8"
              name="exclamation-circle"
              color="error"
              [ariaHidden]="false"
              [attr.title]="'rogers.login.accessibility.error' | translate"
              caption="Attention">
            </ds-icon>
            <div>
              <p damTranslationWithRouting
                class="text-body mb-0 mw-content">
                {{ 'rogers.verifySms.error.tooManyAttemptsOrExpired' | translate }}
              </p>
            </div>
          </div>
        </div>
      </ds-alert>
    </div>
     <!-- <p class="text-body-lg mb-8">
        {{ 'rogers.changeUsername.confirmUsernameForm.body' | translate }}
     </p>
     <div *ngIf="newUsername">
        <p class="text-body mb-10 mt-10 text-bold">  {{ newUsername }}</p>
     </div> -->

     <div *ngIf="newUsername" class="text-body-lg mb-10 mt-10">
      <p><strong>{{ newUsername }}</strong>
         <span translate>{{ confirmUsernameFormbody }}</span></p>
   </div>

     <div class="mb-16">
        <p class="text-body mb-0 mw-content">{{ 'rogers.changeUsername.confirmUsernameForm.middleText' | translate }}</p>
     </div>
     <button
     type="submit"
     ds-button
     class="w-100 mt-12"
     [ngClass]="{'mb-48': hideBackBtn, 'mb-24': !hideBackBtn}"
     title="{{ 'rogers.changeUsername.confirmUsernameForm.continueAlt' | translate }}"
     #continueBtn
     >
     <span *ngIf="!confirmUsernameInProgress">{{ 'common.confirm' | translate }}</span>
     <eas-loader class="eas-loader" *ngIf="confirmUsernameInProgress"></eas-loader>
     </button>
  </form>
</div>
