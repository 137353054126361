// tslint:disable-next-line:only-arrow-functions // aot restrictions
export function environmentNameResolver(url: string): string {
  if (url.indexOf('www.rogers.com') > 0) {
    return 'prod';
  }
  if (url.indexOf('account.rogers.com') > 0) {
    return 'prod';
  }
  if (url.indexOf('www.fido.ca') > 0) {
    return 'prod';
  }
  if (url.indexOf('//dev.localhost.') > 0) {
    return 'dev';
  }

  // uncomment for NON-PROD env's
  // if (url.indexOf('//qa') > 0) {
  //   return url.replace(/.*\/\/(qa[0-9]*).*/, '$1')
  //     .replace('-ciam', '');
  // }
  // if (url.indexOf('//www.qa') > 0) {
  //   return url.replace(/.*(qa[0-9]*).*/, '$1');
  // }
  // if (url.indexOf('//www.pet') > 0 || url.indexOf('//pet') > 0) {
  //   return url.replace(/.*(pet[0-9]*).*/, '$1');
  // }
  // if (url.indexOf('//www.dev') > 0) {
  //   return url.replace(/.*(dev[0-9]*).*/, '$1');
  // }
  // if (url.indexOf('//dev') > 0) {
  //   return url.replace(/.*\/\/(dev[0-9]*).*/, '$1');
  // }
  // if (url.indexOf('//www.env') > 0) {
  //   return url.replace(/.*(env[0-9]*).*/, '$1');
  // }
  if(url.indexOf('staging') > 0) {
    return 'staging';
  }
  if(url.indexOf('//preprod') > 0 || url.indexOf('//www.preprod') > 0) {
    return 'preprod';
  }
  if(url.indexOf('prod01.eks.rogers.com') > 0) {
    return 'eks';
  }
  if (url.indexOf('mock') > 0) {
    return 'mock';
  }
  return 'default';
}
