import { Injectable, OnDestroy } from '@angular/core';
import { WindowService } from '@rogers/cdk/common/services';
import { Brand, CDKCoreService } from '@rogers/cdk/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const globalStyles = {
  rogers: `assets/rogers/css/global/cdk-rogers-global.min.css`,
  fido: `assets/fido/css/global/cdk-fido-global.min.css`,
  chatr: `assets/chatr/css/global/cdk-chatr-global.min.css`,
  r4b: `assets/r4b/css/global/cdk-r4b-global.min.css`,
};

const favicons = {
  rogers: `https://www.rogers.com/app/prod/assets/i/favicon.ico`,
  fido: `https://www.fido.ca/profile/favicon.ico`,
  chatr: `https://www.chatrwireless.com/favicon.ico`,
  r4b: `assets/favicons/r4b/favicon.ico`,
  media: `https://www.sportsnet.ca/sn_favicon.ico`
};

@Injectable({
  providedIn: 'root',
})
export class ScssLoaderService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private linkElemment: HTMLLinkElement;
  private favicon: HTMLLinkElement;
  constructor(
    private cdkCore: CDKCoreService,
    private windowService: WindowService,
    private cookieService: CookieService
  ) {
    // this.createGlobalStylesLink();
    this.createFavicon();
    this.cdkCore.config$.pipe(takeUntil(this.destroy$)).subscribe((config) => {
     // this.updateGlobalStyles(config.brand);
     // const brand = config.brand === 'r4b' || config.brand === 'yahoo' ? 'rogers' : config.brand;
     const brand = config.brand === 'yahoo' ? 'rogers' : config.brand;

     let client = this.cookieService.get('client');
     client = (client === 'cbu' || client === 'yahoo') ? 'rogers' : client;
     this.updateRootDocument(brand);
     this.updateFavicon(client);
    });
  }

  createGlobalStylesLink() {
    if (this.windowService.isBrowser()) {
      this.linkElemment = document.createElement('link');
      this.linkElemment.type = 'text/css';
      this.linkElemment.rel = 'stylesheet';
      document.head.appendChild(this.linkElemment);
    }
  }

  createFavicon() {
    if (this.windowService.isBrowser()) {
      this.favicon = document.createElement('link');
      this.favicon.type = 'image/x-icon';
      this.favicon.rel = 'icon';
      document.head.appendChild(this.favicon);
    }
  }

  updateGlobalStyles(brand: string) {
    this.linkElemment.href = globalStyles[brand];
  }

  updateRootDocument(brand: string) {
    if (this.windowService.isBrowser()) {
      document.documentElement.setAttribute('brand', brand);
    }
  }

  updateFavicon(brand: string) {
    this.favicon.href = favicons[brand];
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
