import {
  AnalyticsService,
  PendingPayloadsService,
  Configurable,
  InterceptorConfig
} from '@rogers/analytics';

import {
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { GlobalStateService } from '../global-state.service';
import { CookieService } from 'ngx-cookie-service';
import { clientMapping, SiteNames } from './analytics-mapping-config';
import { AnalyticsServiceProxy } from './analytics-service-proxy.service';

export function analyticsServiceFactory(
  pendingPayloadsService: PendingPayloadsService,
  router: Router,
  globalStateService: GlobalStateService,
  cookieService: CookieService,
  PLATFORM_ID: {}
) {
  const APPLICATION_NAME = 'EAS|https://github.com/RogersCommunications/iam-eas';
  const pageMap = {
    '': 'MyRogers',
    '/': 'MyRogers'
  };

  const modalFunc = () => {
    return '';
  };
  const configuration = new Configurable();
  configuration.language = globalStateService.currentLanguage$;
  configuration.province = globalStateService.currentProvince$;
  configuration.closeModalResetPage = globalStateService.closeModalResetPage$;
  configuration.personalization = new BehaviorSubject<any>({}).asObservable();
  configuration.account = globalStateService.account$;

  // @ts-ignore
  const analyticsService = new AnalyticsService(
    configuration,
    AnalyticsServiceProxy.getMapping(cookieService.get('brand'), SiteNames),
    pageMap,
    pendingPayloadsService,
    APPLICATION_NAME,
    modalFunc,
    PLATFORM_ID
  );
  analyticsService.interceptorConfigs = [
    {
      shouldInterceptRequest: (req: HttpRequest<any>) => {
        return false;
      },
      shouldInterceptResponse: (
        req: HttpRequest<any>,
        resp: HttpResponse<any>
      ) => {
        return false;
      },
      requestInterceptor: (req: HttpRequest<any>) => {},
      // log an articleView beacon as soon as the article is loaded from the api
      responseInterceptor: (
        req: HttpRequest<any>,
        resp: HttpResponse<any>
      ) => {},
      // should this error be handled?
      shouldHandleError: (req: HttpRequest<any>, err: HttpErrorResponse) => {
        return false; // in DAM we don't have global API tracking
      },
      // long an error beacon when above shouldHandleError is true
      errorHandler: (req: HttpRequest<any>, err: HttpErrorResponse) => {}
    }
  ] as InterceptorConfig[];
  return analyticsService;
}

export const AnalyticsServiceWrapper = (analyticsService: AnalyticsService) => {
  return analyticsService;
};

/*const getMapping = (brandName: string, obj: any) => {
  if (!obj) {
    return;
  }
  const hostFilter = location.hostname.split('.').filter(i => i !== 'www' && i !== 'qa' && i !== 'com' && i !== '.').join('');
  return brandName ? obj[brandName] : hostFilter ? obj[hostFilter] : undefined;
};*/

//export const mapping = getMapping(localStorage.getItem('brand'), clientMapping);

