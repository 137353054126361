import { InjectionToken } from '@angular/core';
import { AnalyticsConfiguration } from './analytics-configuration';
import { GLOBAL_ANALYTICS_CONFIGURATION } from './global-analytics-configuration';
import { GlobalStateService } from '../global-state.service';

export const ANALYTICS_CONFIGURATION = new InjectionToken<AnalyticsConfiguration>('AnalyticsConfiguration');

// tslint:disable-next-line:only-arrow-functions
export function provideAnalyticsConfiguration(config?: AnalyticsConfiguration) {
  return {
    provide: ANALYTICS_CONFIGURATION,
    useValue: !!config ? config : GLOBAL_ANALYTICS_CONFIGURATION
  };
}

// tslint:disable-next-line:only-arrow-functions
export function provideAnalyticsConfigurationUsingClass(analyticsConfigurationClass) {
  return {
    provide: ANALYTICS_CONFIGURATION,
    useClass: analyticsConfigurationClass
  };
}

// tslint:disable-next-line:only-arrow-functions
export function provideAnalyticsConfigurationUsingFactory(verifyAnalyticsConfigurationFactory) {
  return {
    provide: ANALYTICS_CONFIGURATION,
    useFactory: verifyAnalyticsConfigurationFactory,
    deps: [GlobalStateService]
  };
}
