import { PasswordFormSessionComponent } from './passwordFormSession.component';
import { ComponentFactory, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { SetPasswordFormComponent } from '../set-password-form/set-password-form.component';
import { ConfirmPasswordComponent } from '../change-password/confirm-password/confirm-password.component';
export class PasswordAuthenticatorSession {
  clientContext: any;
  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;
  passwordFormSessionCompRef: ComponentRef<PasswordFormSessionComponent>;
  setPasswordFormSessionCompRef: ComponentRef<SetPasswordFormComponent>;
  confirmPasswordChangeComponent: ComponentRef<ConfirmPasswordComponent>;

  actionContext: any;
  submitHandler: any;
  mode: any;
  title: string;
  username: string;
  description: any;
  actionEscapeOption: any;


  constructor(title, username){
      this.title = title;
      this.username = username;
      console.log(`Constructor title ${this.title}`);
      console.log(`Constructor username ${this.username}`);
  }
  // this.submitHandler = null;

  startSession(
    description: com.ts.mobile.sdk.AuthenticatorDescription,
    mode: com.ts.mobile.sdk.AuthenticatorSessionMode,
    actionContext: com.ts.mobile.sdk.PolicyAction | null,
    clientContext: object | null
  ): void {
    console.log('Started new ${mode} password session');
    this.actionContext = actionContext;
    this.clientContext = clientContext;
    this.mode = mode;

    // Get Authenticator description
    this.description = description;
    this.actionEscapeOption = false;
  }

  endSession(): void {
    console.log('Form session ended');
    this.clientContext['cookieService'].delete('errorPwdCode');
  }

  promiseInput(): Promise<com.ts.mobile.sdk.InputOrControlResponse<ResponseType>>{
    const self = this;
    self.resolver = (self.clientContext as any).resolver;
    self.viewContainerRef = (self.clientContext as any).viewContainerRef;
    self.viewContainerRef.clear();
    const userId = this.clientContext['userID'];

    // To check if a credential recovery escape has been configured for the specific authentication method,
    // the UI Layer implementation should consult AuthenticatorDescription.getCredentialRecoveryEscapeOption:
    if (this.description.getCredentialRecoveryEscapeOption()) {
        this.actionEscapeOption = true;
        console.log("Action Escape Option present");
     }

    if (self.mode === com.ts.mobile.sdk.AuthenticatorSessionMode.Authentication) {
      const factory: ComponentFactory<PasswordFormSessionComponent> = self.resolver.resolveComponentFactory(PasswordFormSessionComponent);
      self.passwordFormSessionCompRef = self.viewContainerRef.createComponent(factory);
      self.passwordFormSessionCompRef.instance.onSubmitCode = self.onSubmitCode;
      self.passwordFormSessionCompRef.instance.username = userId;

      self.passwordFormSessionCompRef.instance.onForgotPassword = self.onForgotPasswordClick.bind(self);

      self.passwordFormSessionCompRef.instance.onChangeUsernameClick = self.onChangeUsername.bind(self);
    }

    else if (self.mode === com.ts.mobile.sdk.AuthenticatorSessionMode.Registration){
      const fact: ComponentFactory<SetPasswordFormComponent> = self.resolver.resolveComponentFactory(SetPasswordFormComponent);
      this.setPasswordFormSessionCompRef = self.viewContainerRef.createComponent(fact);
      this.setPasswordFormSessionCompRef.instance.username = userId;

      this.setPasswordFormSessionCompRef.instance.onResetPasswordSubmit = self.onResetPasswordSubmit;
    }
    else{
    }

    return new Promise((resolve, reject) => {
      self.submitHandler = (response) => {
        resolve(response);
      };
    });
  }

  promiseRecoveryForError(
    error: com.ts.mobile.sdk.AuthenticationError,
    validRecoveries: Array<com.ts.mobile.sdk.AuthenticationErrorRecovery>,
    defaultRecovery: com.ts.mobile.sdk.AuthenticationErrorRecovery
  ): Promise<com.ts.mobile.sdk.AuthenticationErrorRecovery> {
    return new Promise((resolve, reject) => {
      console.log(`promiseRecoveryForError was called with error: ${error}`);

      if (this.mode ===  com.ts.mobile.sdk.AuthenticatorSessionMode.Authentication){
      const newLocal = 'additional_data';
      const errorCode = error.getData()[newLocal].code;
      if (errorCode === 'UE03'){ // incorrect password
        setTimeout(() => this.passwordFormSessionCompRef.instance.setErrorCode(errorCode), 100);
        resolve(com.ts.mobile.sdk.AuthenticationErrorRecovery.RetryAuthenticator);
      }
      else if (errorCode === 'UE04'){ // user locked after password tries done
        // setTimeout(() => this.passwordFormSessionCompRef.instance.setErrorCode(errorCode));

        // resolve(defaultRecovery);
        // resolve(com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail);

        // To return to client app with error
        this.returnToClientApplicationWithError(errorCode, 'Account Locked');
      }
      else {
        // technical error SE01. Show on SPA.
        setTimeout(() => this.passwordFormSessionCompRef.instance.setErrorCode('SE01'), 100);
        resolve(com.ts.mobile.sdk.AuthenticationErrorRecovery.RetryAuthenticator);
        resolve(defaultRecovery);
      }
    }
    else if (this.mode ===  com.ts.mobile.sdk.AuthenticatorSessionMode.Registration){

    }

    });
  }


  returnToClientApplicationWithError(error, errorDescription) {
    const url = this.clientContext['cookieService'].get('redirect_uri');
    console.log('redirect_uri : ', url);

    let state = this.clientContext['cookieService'].get('state');
    console.log('state : ', state);
    let appendChar = '?';
    if (url.includes('?'))
    {
       appendChar = '&';
    }
    window.location.href = url + appendChar + 'error=' + error
       + '&error_description=' +  errorDescription
       + '&lang=' + this.clientContext['cookieService'].get('lang')
       + '&state=' + state;
    return;

  }


  changeSessionModeToRegistrationAfterExpiration() {
    console.log('Password Expired.');
  }

  /** Non UIFormSession interface Functions */
  onSubmitCode = (code: string) => {
    const inputResponse = com.ts.mobile.sdk.PasswordInput.create(code);
    const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputResponse);
    this.clientContext['cookieService'].set('errorPwdCode', code, undefined, undefined, null, true, 'None');
    this.submitHandler(response);
  }

  onResetPasswordSubmit = (password: string) => {
    const inputResponse = com.ts.mobile.sdk.PasswordInput.create(password);
    const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputResponse);
    this.submitHandler(response);
  }

  private onForgotPasswordClick(){

    const self = this;
    // If the user chooses this option, the UI implementation should trigger
    // the escape by creating an escape request with the provided option and returning it
    // from UIAuthenticatorSession.promiseInput
    const forgotPasswordEscapeOption =
            self.actionContext.escapeOptionById(
              self.description.getCredentialRecoveryEscapeOption().getId());

    if (!forgotPasswordEscapeOption) {
        console.error(`unable to find a "Forgot Password" option in actionContext.escapeOptions`);
    }
    else
    {
      self.submitHandler(com.ts.mobile.sdk.InputOrControlResponse.createEscapeResponse(forgotPasswordEscapeOption, new Object()));
      this.clientContext['nextStep'] = 'forgotPasswordFlow';
    }
  }

  private onChangeUsername(){
    const url = this.clientContext['redirect_uri'];
    const state = this.clientContext['state'];
    const errorCode  = 'change_username';
    let appendChar = '?';
    if (url.includes('?'))
    {
       appendChar = '&';
    }
    window.location.href = url + appendChar + 'error=' + errorCode + '&error_description=username'
    + '&lang=' + this.clientContext['cookieService'].get('lang')
    + '&state=' + state;
  }
}
export default PasswordAuthenticatorSession;
